<div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
                    <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="width: 30%;margin-top: 15px;" alt="Chatstasy" />
                </div>
            </div>
        </div>
    </div>
      
    <div class="row" style="overflow:auto; height:550px;">
        <div style="width: 100%;">
            <div *ngIf="alreadyConfirm == 'true'">
                <p-card header="Link Expired" styleClass="p-shadow-5">
                  <div class="content">
                    <p class="message">
                      The link you tried to access has expired.
                    </p>
                  </div>
                </p-card>
            </div>
            <p-card *ngIf="alreadyConfirm == null ">
                <!-- <ng-template pTemplate="header">
                    <div class="text-center" style=" text-transform: uppercase;font-size: 20px;padding-top: 15px; ">
                        Appointment Confirmation
                    </div>
                </ng-template>
                <div class="row">
                    <div style=" width: 100%; ">
                        <h2 *ngIf="statusInfo == 'pending'" style=" text-align: center;">Please wait...</h2>
                        <h2 *ngIf="statusInfo == 'success'" style=" text-align: center;">Thank you for confirming</h2>
                        <h2 *ngIf="statusInfo == 'error'" style=" text-align: center;">Unexpected Error Occured. Please Try Again Later</h2>
                    </div>
                </div>
                <ng-template pTemplate="footer">
                    <div class="copy_right" style=" text-align: center; ">
                        &copy;
                        <span class="current-year"></span>
                        <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                        <span>All rights reserved</span>
                    </div>
                </ng-template> -->
                  
                <div class="text-center" style="text-transform: uppercase; font-size: 15px;">
                    <div style="display: flex; align-items: center; justify-content: center; gap: 10px;">
                        <img 
                          [src]="businessLogoUrl" 
                          style="height: 55px; border-radius: 4px; max-width: 100px; object-fit: contain;" 
                          alt="Chatstasy" 
                          (error)="fileFailedToLoadBusinessLogo($event)"
                        >
                        <h1 style="margin: 0;">{{businessInfo?.name}}</h1>
                      </div>
                    <div [innerHTML]="appointmentDetails"></div>
                    <div class="center">
                        <p-button label="CANCEL" styleClass="p-button-danger" icon="pi pi-times" [ngStyle]="{ 'margin': '1px' }" (click)="cancelAppointmentConfirmDialog()"></p-button>
                        <p-button label="ACCEPT" styleClass="p-button-success" icon="pi pi-check" [ngStyle]="{ 'margin': '1px' }" (click)="confirmAppointmentConfirmDialog()"></p-button>
                    </div>
                    <h6><b><i>To reschedule, please call during normal business hours.</i></b></h6>
                </div>
            </p-card>
            <div class="p-d-flex p-jc-center p-ai-center" style="height: 300px; text-align: center;"  *ngIf="alreadyConfirm == 'confirmSuccess'">
                <h1><b><i>Appointment Confirmed Successfully.</i></b></h1>
            </div>

            <div class="p-d-flex p-jc-center p-ai-center" style="height: 300px; text-align: center;"  *ngIf="alreadyConfirm == 'cancelSuccess'">
                <h1><b><i>Appointment Cancelled Successfully.</i></b></h1>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right" key="errMsg" [baseZIndex]="10000"></p-toast>
<p-toast position="bottom-right" key="br"></p-toast>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width: dialogWidth}">>
</p-confirmDialog>