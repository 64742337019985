<div class="p-d-flex p-jc-center p-ai-center" style="height: 100vh; padding: 20px 0 10px 0;overflow: auto;">
    <div class="card_width">
        <div class="p-d-flex p-jc-center p-ai-center p-mb-3" [ngStyle]="currentPosition=='business_info' && {'padding-top':'400px'} || {'padding-top': '0px'}">
            <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="height: 20%;width: 40%;" alt="Chatstasy" />
        </div>
        <p-card>
            <form class="form-login" (ngSubmit)="onSubmit(signUpForm)" #signUpForm="ngForm">
                <fieldset class="fieldset">
                    <legend>Sign Up - {{titleText}}</legend>
                    <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                    <div [ngClass]="currentPosition=='business_url'?'show':'hide'">
                        <div class="row form-group p-mt-2 p-d-flex p-jc-center p-ai-center" style="height: 300px;">
                            <label class="col-sm-3 control-label">Business Url<span
                                    class="symbol required">
                                    
                                    <a title="Example IBM, NailSap, Microsoft, Yoga">
                                        <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </a>

                                </span></label>
                            <div class="col-sm-9">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">https://chatstasy.biz/</span>
                                    <input appBlockCopyPaste pInputText type="text" name="url"
                                        [(ngModel)]="shortNameUrl" placeholder="Enter business url"
                                        (keypress)="keyPressAlphaNumericWithCharacters($event)"
                                        (input)="checkBusinessUrl()" max="20" required />
                                </div>
                                <span *ngIf="shortNameUrl && !disabledShortUrl">
                                    Your Business Url: <a style="cursor:pointer;color:#004999;">https://chatstasy.biz/{{shortNameUrl}}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="currentPosition=='business_info'?'show':'hide'">
                        <div class="p-grid p-ai-center" *ngIf="!redirectingToLogin">
                            <div class="p-col p-md-4 p-lg-4">
                                <div class="row">
                                    <div class="form-group col-sm-10 text-center">
                                        <label class="text-center">Business Logo</label>
                                        <div class="fileinput fileinput-new text-center">
                                            <div class="user-image mt-2">
                                                <div class="fileinput-new img-thumbnail">
                                                    <img style="width: 100px;height: 100px;object-fit: contain;"
                                                        [src]="businessProfileUrl" alt=""
                                                        (error)="fileFailedToLoad($event)" />
                                                </div>
                                                <div class="user-image-buttons" style="display:block;">
                                                    <span class="btn btn-azure btn-file btn-sm"
                                                        (click)="displayCropModal = true">
                                                        <span class="fileinput-new">
                                                            <i class="fa fa-pencil"></i>
                                                        </span>
                                                    </span>
                                                    <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                        (click)="removeImageLogo()"
                                                        class="btn fileinput-exists btn-red btn-sm">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col p-md-8 p-lg-8 p-fluid">
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Business Name<span
                                            class="symbol required">
                                            
                                            <a title="Microsoft Corporation, International Business Machines, Meta">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </span></label>
                                    <div class="col-sm-8">
                                        <input type="text" name="businessName" class="form-control"
                                            placeholder="Enter Business Name" [(ngModel)]="businessName"
                                            [disabled]="disabled" required />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Address 1<span
                                            class="symbol required"></span>
                                            
                                            <a title="Redmond, Washington, United States">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <textarea pInputTextarea type="text" name="address" class="form-control"
                                            placeholder="Enter Address 1" [(ngModel)]="address" [disabled]="disabled"
                                            required></textarea>
                                    </div>
                                </div>

                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Address 2
                                            
                                            <a title="Redmond, Washington, United States">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <textarea pInputTextarea type="text" name="address2" class="form-control"
                                            placeholder="Enter Address 2" [(ngModel)]="address2" [disabled]="disabled"></textarea>
                                    </div>
                                </div>


                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">City<span
                                            class="symbol required"></span>
                                            
                                            <a title="New York, Los Angeles, Chicago">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <input type="text" name="city" class="form-control" placeholder="Enter city"
                                            [(ngModel)]="city" [disabled]="disabled" autocomplete="off"/>
                                    </div>
                                </div>


                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">State<span
                                            class="symbol required"></span>
                                            
                                            <a title="State two letters (AZ, AR, CA, CO)">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <input type="text" name="business_state" class="form-control" placeholder="Enter state"
                                            [(ngModel)]="business_state" [disabled]="disabled" autocomplete="off" maxlength="2" value="{{business_state | uppercase}}"/>
                                    </div>
                                </div>

                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Zip Code<span
                                            class="symbol required"></span>
                                            
                                            <a title="Zip Code 00001 – 99999">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <input type="text" name="zipcode" class="form-control" placeholder="Enter Zip Code"
                                            [(ngModel)]="zipcode" [disabled]="disabled" (keypress)="validateDigitOnly($event)" autocomplete="off" maxlength="5"/>
                                    </div>
                                </div>

                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Country<span
                                            class="symbol required"></span>
                                           
                                            <a title="United States of America, Mexico, Dubai">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <select [(ngModel)]="country" name="country" class="form-control"
                                            placeholder="Select a country" style="height: 34px;" required>
                                            <option [value]="null" selected disabled>Select Country</option>
                                            <option *ngFor="let country of countries" [ngValue]="country.isoCode">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Phone<span
                                            class="symbol required"></span>
                                            
                                            <a title="81234 56789">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <span class="input-icon"><input type="text" class="form-control"
                                                placeholder="Enter Phone" name="phone" [(ngModel)]="phone"
                                                [disabled]="disabled" maxlength="10"
                                                (keypress)="validateDigitOnly($event)">
                                            <span class="number">+1</span></span>
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Fax
                                        
                                        <a title="323 555 1234">
                                            <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                          </svg>
                                        </a>

                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text" name="fax" class="form-control" placeholder="Enter Fax"
                                            [(ngModel)]="fax" [disabled]="disabled"
                                            (keypress)="validateDigitOnly($event)" />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">EIN<span
                                            class="symbol required"></span>
                                            
                                            <a title="12-3456789">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <input type="text" name="ein" class="form-control" placeholder="Enter EIN"
                                            [(ngModel)]="ein" [disabled]="disabled"
                                            (keypress)="validateDigitOnly($event)" />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Website

                                        <a title="http://google.com, www.yahoo.com">
                                            <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                          </svg>
                                        </a>

                                    </label>
                                    <div class="col-sm-8">
                                        <input type="url" name="website" class="form-control"
                                            placeholder="Enter Website Url" [(ngModel)]="websiteUrl"
                                            [disabled]="disabled" />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Timezone<span
                                            class="symbol required"></span>
                                        
                                            <a title="(GMT -8:00) PST8PDT">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <select [(ngModel)]="timezone" name="timezone" class="form-control"
                                            placeholder="Select a timezone" style="height: 34px;" required>
                                            <option [value]="null" disabled>Select a timezone</option>
                                            <option *ngFor="let data of timezoneList" [ngValue]="data">
                                                {{ Splitfunction(data,2) }} {{ Splitfunction(data,0) }}  {{ Splitfunction(data,1) }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Business Type<span
                                            class="symbol required"></span>
                                        
                                            <a title="ACCOUNTANT, ACUPUNCTURIST, BEAUTICIAN">
                                                <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                              </svg>
                                            </a>

                                        </label>
                                    <div class="col-sm-8">
                                        <select [(ngModel)]="selectedBusinessType" name="businessType"
                                            class="form-control" style="height: 34px;"
                                            [ngClass]="disabled? 'select_disabled':''" [disabled]="disabled">
                                            <option [value]="null" disabled>Select business type</option>
                                            <option *ngFor="let business of businessType" [value]="business.id">
                                                {{ business.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-d-flex p-jc-center p-ai-center" style="height: 300px;" *ngIf="redirectingToLogin">
                            <div class="p-mb-2 center">Redirecting to login in {{countNumber}} sec...
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="currentPosition=='user_info'?'show':'hide'">
                        <div class="p-grid p-ai-center">
                            <div class="p-col">
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">First Name<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <input type="text" name="firstName" class="form-control"
                                            placeholder="Enter First Name" [(ngModel)]="firstName" [disabled]="disabled"
                                            required />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Last Name<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <input type="text" name="lastName" class="form-control"
                                            placeholder="Enter Last Name" [(ngModel)]="lastName" [disabled]="disabled"
                                            required />
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Email<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <input type="email" placeholder="Enter Email" id="email" name="email"
                                            class="form-control" [(ngModel)]="email" [disabled]="disabled" required />
                                    </div>
                                </div>

                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Mobile<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <span class="input-icon"><input type="text" class="form-control"
                                                placeholder="Enter Mobile" name="mobile" [(ngModel)]="mobile"
                                                [disabled]="disabled" maxlength="10"
                                                (keypress)="validateDigitOnly($event)">
                                            <span class="number">+1</span></span>
                                    </div>
                                </div>
                                <div class="row form-group" style="margin-bottom: 10px;">
                                    <label class="col-sm-4 control-label">Country<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <select [(ngModel)]="userCountry" name="country" class="form-control"
                                            placeholder="Select a country" style="height: 34px;" required>
                                            <option [value]="null" selected disabled>Select Country</option>
                                            <option *ngFor="let country of countries" [ngValue]="country.isoCode">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="col-sm-4 control-label">Password<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <input [(ngModel)]="profileNewPassword" class="form-control" type="password"
                                            name="newPassword"
                                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                            placeholder="Enter Password" #newPassword="ngModel" [disabled]="disabled" />

                                    </div>
                                    <small *ngIf="newPassword.invalid" style="font-size: 13px;"
                                        class="p-error p-ml-3 p-mt-1">Password should be
                                        of
                                        minimum
                                        8 character and contain
                                        atleast one
                                        uppercase letter, one number, one special character.
                                    </small>
                                </div>
                                <div class="row form-group">
                                    <label class="col-sm-4 control-label">Confirm Password<span
                                            class="symbol required"></span></label>
                                    <div class="col-sm-8">
                                        <input [(ngModel)]="profileConfirmPassword" type="password"
                                            name="profileConfirmPassword" class="form-control"
                                            placeholder="Re-enter Password" #confirmPassword="ngModel" required
                                            pattern="{{ newPassword.value }}" [disabled]="disabled" />
                                        <small *ngIf="confirmPassword.errors?.pattern" class="p-error">
                                            Passwords do not match.
                                        </small>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
        
                    <div [ngClass]="currentPosition=='otp'?'show':'hide'">
                        <div class="p-d-flex p-jc-center p-ai-center" style="height: 300px;">
                            <div *ngIf="!redirectingToLogin" class="p-d-flex p-flex-column p-jc-center p-ai-center">
                                <div class="p-mb-2 center">We’ve sent a One Time Password (OTP) to your email <span
                                        style="font-weight: bold;">{{email}}</span>. Please
                                    enter it to complete verification.
                                </div>
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,allowNumbersOnly:true}">
                                </ng-otp-input>
                                <div *ngIf="!showResendLayout" class="p-mb-2 p-mt-4 center">Please wait
                                    {{resendCountNumber}} second(s) before requesting a new One Time Password (OTP).
                                </div>
                                <div *ngIf="showResendLayout" class="p-mb-2 p-mt-4 center">Didn't get email ? <a
                                        href="javascript:void(0)" (click)="resendOtp()">Resend OTP</a>
                                </div>
                            </div>
                            <div *ngIf="redirectingToLogin">
                                <div class="p-mb-2 center">Redirecting to login in {{countNumber}} sec...
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- footer -->
                    <div>
                        <div [ngClass]="currentPosition=='business_url'?'show':'hide'">
                            
                            <div class="p-grid">
                                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                        class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('otp')"></button>
                                </div>
                                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                                        label="Next" class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('business_info')"></button>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="currentPosition=='business_info'?'show':'hide'">
                            <div *ngIf="!redirectingToLogin">                            
                                <div class="p-col-6" class="center">
                                    <div class="p-field-checkbox p-jc-center p-ai-center">
                                        <p-checkbox [(ngModel)]="agreeValue" name="agree" [binary]="true"
                                            inputId="agree" (onChange)="enableDisableRegister()">
                                        </p-checkbox>
                                        <label class="p-mt-1" for="agree">By clicking register, you agree to our <a
                                                href="http://chatstasyapp.com/terms-of-use/" target="_blank">Terms
                                                and conditions.</a></label>
                                    </div>

                                    <div class="p-d-flex p-flex-column p-jc-center p-ai-center">
                                        <p-button type="button" label="Register" icon="pi pi-arrow-circle-right"
                                            iconPos="right" [disabled]="disabledOtp" (click)="paymentProcess()">
                                        </p-button>
                                    </div>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                        <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                            class="p-button-secondary p-button-text"
                                            (click)="goToNextAndPrevious('business_url')"></button>
                                    </div>
                                    <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [ngClass]="currentPosition=='user_info'?'show':'hide'">
                            <div class="p-grid">
                                
                                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                                        label="Next" class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('otp')"></button>
                                </div>
                            </div>
                        </div>
                        
                        <div [ngClass]="currentPosition=='otp'?'show':'hide'">
                            <div *ngIf="!redirectingToLogin" class="p-grid">
                                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                        class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('user_info')"></button>
                                </div>
                                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                                        label="Next" class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('business_url')"></button>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="error" class="center mb-2">
                            <label style="color: red;">
                                {{error}}
                            </label>
                        </div>
                        <!-- <div *ngIf="currentPosition=='business_url'" class="new-account center">Already have an account?
                            <a href="javascript:void(0)" routerLink="/login">Log-in</a>
                        </div> -->
                    </div>

                </fieldset>
                <div *ngIf="isLoading" class="progressLayout">
                    <div class="progressContainer">
                        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                        </p-progressSpinner>
                    </div>
                </div>
            </form>
            <div>
                <div class="copy_right">
                    &copy;
                    <span class="current-year"></span>
                    <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                    <span>All rights reserved</span>
                </div>
                <a class="cancel_registration" href="javascript:void(0)" (click)="confirmCancelDialog()">Cancel
                    Registration</a>
            </div>


        </p-card>
    </div>

</div>
<!-- CANCEL MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{'min-width':'45vw'}">
</p-confirmDialog>
<!-- CROP MODAL -->
<app-image-cropper-dialog #renew [displayCropModal]="displayCropModal" (hideCropModal)="hideCropModal($event)"
    (croppedImage)="croppedImage($event)">
</app-image-cropper-dialog>

<app-renew-modal #renew [displayRenewModal]="displayRenewModal" from="home"
    (hideModalRenewDialog)="hideModalRenewDialog($event)" (isSuccess)='isSuccess($event)'>
</app-renew-modal>