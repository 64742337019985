import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';
import { DomSanitizer} from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { element } from 'protractor';
import { ActionButtonService } from '../services/action-button/action-button.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

interface Week {
  weekName: string;
}

interface Month {
  monthName: string;
  monthNumber: number;
}

interface DropdownOption {
  label: string;
  value: number;
}

@Component({
  selector: 'app-service-type',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.css'],
})
export class ServiceTypeComponent implements OnInit {
  @ViewChild('p', { static: false }) paginator?: Paginator;
  items: MenuItem[] = [{ label: 'Manage Services' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  isLoading: boolean = false;
  serviceTypeList: any[] = []
  cols: any[] = [];

  //form variable
  serviceId = '';
  serviceName: string = '';
  serviceDuration: string = '';
  specialInstructions: string = '';
  location: string = '';
  autoSchedule: boolean = false;
  recurring: boolean = false;
  recurrent: string = 'null';
  tooltipMsg: string = 'Customers can not schedule appointments themselves';
  reminderDayList: any[] = []
  //Paginator variable
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;
  // Dialog display varaible
  headerName: string = '';
  displayAddEditModal: boolean = false;
  displayFilesModal: boolean = false;
  disabled: boolean = false;
  public innerWidth: any;
  currentPosition: string = 'service_details';
  serviceAppointmentReminder: any= "true";
  serviceDescription: string = '';
  // serviceSpecialInstructions: string = '';
  serviceCost: number = 0;
  serviceType: string = 'One-On-One';
  serviceCategory: string = 'Recurring Daily';
  serviceClinetConfirmation: any= "true";
  serviceAppointmentScheduling: string = 'By Client';
  clientAppointmentRescheduling: string = 'true';
  appointmentWaitinglistAllowed: string = 'false';
  prePayment: string = 'false';
  concurrency: string= 'false';
  serviceImage: any[] = [];

  walkInsAllowed: string= 'false';
  serviceUrl: string = '';
  serviceVideoUrl: string = '';
  // serviceRangeValue: number[] = [2,100];
  serviceRangeValue: number = 2;
  getRangeValue: DropdownOption[] = Array.from({ length: 98 }, (_, i) => ({ label: `${i + 2}`, value: i + 2 }));
  serviceVideo: any[] = [];
  serviceImageFile: any[] = [];
  serviceVideoFile: any[] = [];
  BaseURL:string = environment.IMAGE_BASE_URL;
  @ViewChild('largeModal') largeModal:any;
  selectImage:string = '';
  serviceHours: string = '00';
  serviceMints: string = '00';
  @ViewChild('FileUpload') fileUpload: any;
  checkFilesChanged: boolean = false;
  weekList: Week[] = [];
  selectedWeek: Week[] = [];
  monthList: Month[] = [];
  selectedMonth!: Date[];
  validateMonth: any[] = [];
  @ViewChild('calendarMonthly') datePicker: any;
  @ViewChild('calendarNonRecurring') calendarNonRecurring: any;
  @ViewChild('calendarCustom') calendarCustom: any;
  minDate!: Date;
  selectedDate!: Date;
  serviceCategoryDetails: any;
  concurrencyCountArray = new Array(50).fill(null).map((_, i) => i + 1);
  concurrencySelectedNumber:number = 2;
  groupConcurrencyArray: any[] = [];
  groupConcurrencyCountArray: any = [2];
  prePaymentUrl: string = '';
  prePaymentType: string = 'URL';
  arrayActionButton: any[] = [''];
  selectedActionButton: any;
  serviceTime: string= 'true';
  customStartTime!: Date | undefined;
  @ViewChild('startTime') startTime: any;
  appointmentsPerDay: string= 'true';
  customAppointmentPerDay: number = 1;
  options: DropdownOption[] = Array.from({ length: 25 }, (_, i) => ({ label: `${i + 1}`, value: i + 1 }));
  appointmentReminderDaysNumber = new Array(5).fill(null).map((_, i) => i + 1);
  reminderDays: number = 1;
  serviceActivation: string = 'immediate';
  serviceStartDate!: Date;
  @ViewChild('serviceDate') serviceDate: any;
  // defaultServiceProvider: boolean = true;
  // canDelete: boolean = true;
  sortBy: any[] = [];
  selectedSortBy: any;
  searchInputText: string = '';

  constructor(
    public utils: UtilsService,
    private serviceTypeService: ServiceTypeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer:DomSanitizer,
    private actionButtonService: ActionButtonService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'name', header: 'Service Name' },
      { field: 'serviceDuration', header: 'Service Duration' },
      { field: 'location', header: 'Service Location' },
      // { field: 'specialInstructions', header: 'Special Instructions' },
      { field: 'serviceCategory', header: 'Service Category' },
      { field: 'serviceType', header: 'Service Type' },
    ]
    for (let index = 1; index <= 10; index++) {
      this.reminderDayList.push({ name: index + ' Days', value: index })
    }
    this.listServiceType(this.currentPage, this.searchInputText);

    this.weekList = [
      { weekName: 'Sunday' },
      { weekName: 'Monday' },
      { weekName: 'Tuesday' },
      { weekName: 'Wednesday' },
      { weekName: 'Thursday' },
      { weekName: 'Friday' },
      { weekName: 'Saturday' },
    ];

    this.monthList = [
      { monthName: 'January', monthNumber: 1 },
      { monthName: 'February', monthNumber: 2 },
      { monthName: 'March', monthNumber: 3 },
      { monthName: 'April', monthNumber: 4 },
      { monthName: 'May', monthNumber: 5 },
      { monthName: 'June', monthNumber: 6 },
      { monthName: 'July', monthNumber: 7 },
      { monthName: 'August', monthNumber: 8 },
      { monthName: 'September', monthNumber: 9 },
      { monthName: 'October', monthNumber: 10 },
      { monthName: 'November', monthNumber: 11 },
      { monthName: 'December', monthNumber: 12 },
    ];
    this.minDate = new Date();

    this.actionButtonService.ActionButtonlistByBusinessIdAndStatus().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Keyword Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayActionButton = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }


  setCategoryValue(){
    this.serviceCategoryDetails = [];
    if(this.serviceCategory == 'Recurring Weekly' || this.serviceCategory  == 'Recurring Bi-Weekly') {
      this.selectedWeek.forEach((element:any, index)=>{
        this.serviceCategoryDetails.push({name:element.weekName, seat:this.groupConcurrencyArray[index]});
      });
    }
    
    if(this.serviceCategory  == 'Recurring Monthly' || this.serviceCategory == 'Recurring Custom') {
      this.selectedMonth.forEach((element:any, index)=>{

        // Convert the string to a Date object
        let dateObject = new Date(element);

        // // Set the time components to zero
        // dateObject.setUTCHours(0, 0, 0, 0);
        let year = dateObject.getFullYear();
        let month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let day = String(dateObject.getDate()).padStart(2, '0'); // Day of the month

        // Convert the Date object back to a string
        // let newDateString = dateObject.toISOString();
        let newDateString = `${year}-${month}-${day}T00:00:00.000Z`;
        this.serviceCategoryDetails.push({name:newDateString, seat:this.groupConcurrencyArray[index]});
      });    
    }

    if(this.serviceCategory == 'Non-Recurring') {   
      // Convert the string to a Date object
      let dateObject = new Date(this.selectedDate);

      // Set the time components to zero
      // dateObject.setUTCHours(0, 0, 0, 0);

      // Convert the Date object back to a string
      // let newDateString = dateObject.toISOString();

      // Get the year, month, and date components
      let year = dateObject.getFullYear();
      let month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      let day = String(dateObject.getDate()).padStart(2, '0'); // Day of the month
      
      // Create the formatted date string in the desired format
      let newDateString = `${year}-${month}-${day}T00:00:00.000Z`;

      this.serviceCategoryDetails = newDateString;
    }
  }

  addService(serviceForm: NgForm) {
    if (this.ValidateServiceDetails() == 'null') {
      return;
    }

    if(this.validatePrepaymentURL() == 'null'){
      return;
    }

    this.messageService.clear('errMsg');
    if (this.serviceUrl != '' && !CommonFunctions.validURL(this.serviceUrl)) {
      this.showErrorMessage('URL is invalid.')
      this.hideProgressBar();
      return 'null';
    }

    if (this.serviceVideoUrl != '' && !CommonFunctions.validURL(this.serviceVideoUrl)) {
      this.showErrorMessage('Sevice video URL is invalid.')
      this.hideProgressBar();
      return 'null';
    }
    
    let range = null;
    
    if(this.serviceType != "One-On-One"){
      // range = this.serviceRangeValue.toString()
      range = this.serviceRangeValue;
    }   
    
    this.setCategoryValue();

    let setServiceCategoryDetails = null;
    if(this.serviceCategory != "Recurring Daily"){
      setServiceCategoryDetails = JSON.stringify(this.serviceCategoryDetails)
    }

    let prePaymentDetails = {};
    if(this.prePayment == "true"){
      if(this.prePaymentType == "URL"){
        prePaymentDetails = {
          prePaymentType: this.prePaymentType,
          value: this.prePaymentUrl
        }
      }else{
        prePaymentDetails = {
          prePaymentType: this.prePaymentType,
          value: this.selectedActionButton.id
        }
      } 
    }

    let serviceDate = null;
    if(this.serviceActivation != "custom"){
      serviceDate = null;
    }else{
      serviceDate = new Date(this.serviceStartDate);
    }
    
    const serviceData = {
      name: this.serviceName,
      serviceDescription: this.serviceDescription,
      specialInstructions: this.specialInstructions ? this.specialInstructions : null,
      location:  this.location,
      serviceCost: this.serviceCost,
      serviceDuration: this.serviceHours+':'+this.serviceMints,
      serviceType: this.serviceType,
      serviceRange: range,
      serviceCategory: this.serviceCategory,
      serviceCategoryDetails: setServiceCategoryDetails,
      concurrency: this.concurrency,
      concurrencySeats: this.concurrency == "true" ? this.concurrencySelectedNumber : null,
      // concurrencyRange: this.myObject.toString(),
      serviceAppointmentReminder: this.serviceAppointmentReminder,
      serviceAppointmentScheduling: this.serviceAppointmentScheduling,
      clientAppointmentRescheduling: this.clientAppointmentRescheduling,
      appointmentWaitinglistAllowed: this.appointmentWaitinglistAllowed,
      walkInsAllowed: this.walkInsAllowed,
      prePayment: this.prePayment,
      serviceUrl: this.serviceUrl ? this.serviceUrl : null,
      serviceVideoUrl: this.serviceVideoUrl ? this.serviceVideoUrl : null,
      // serviceImage: this.serviceImage,
      // serviceVideo: this.serviceVideo,
      serviceClinetConfirmation: this.serviceClinetConfirmation,
      autoSchedule: null,
      reminderDays: this.reminderDays,
      prePaymentUrl: this.prePayment == "true" ? JSON.stringify(prePaymentDetails) : null,
      serviceTime: this.serviceTime,
      // moment(this.customStartTime).format('HH:mm')
      // customStartTime: this.formatTimeString(this.customStartTime?.getHours(),this.customStartTime?.getMinutes()),
      customStartTime: moment(this.customStartTime).format('HH:mm'),
      appointmentsPerDay: this.appointmentsPerDay,
      customAppointmentsPerDay: this.customAppointmentPerDay,
      serviceActivation: this.serviceActivation,
      serviceStartDate: this.datePipe.transform(this.serviceStartDate, 'dd-MM-yyyy')!,
      // defaultServiceProvider: this.defaultServiceProvider
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    console.log('Add Service Form Data --->> ' + JSON.stringify(data));
    this.serviceTypeService.addServiceType(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Service Type Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          let serviceId = json.data.id;
          
          if(this.serviceImage.length > 0){
            this.serviceImage.forEach((obj:any, index:any) => {
              this.serviceTypeService.serviceFileUpload(obj, 'image', serviceId, index).subscribe(
              (response) => {
                var json = JSON.parse(JSON.stringify(response));
                console.log('Status --->> ' + json.response.status);
              },
              (error) => {
                console.log(error);
                this.showErrorMessage(AppSettings.ERROR_MSG)
                this.hideProgressBar();
              });
            });
          }  
          
          if(this.serviceVideoFile.length > 0){
            this.serviceVideoFile.forEach((obj:any, index:any) => {
              this.serviceTypeService.serviceFileUpload(obj, 'video', serviceId, index).subscribe(
              (response) => {
                var json = JSON.parse(JSON.stringify(response));
                console.log('Status --->> ' + json.response.status);
              },
              (error) => {
                console.log(error);
                this.showErrorMessage(AppSettings.ERROR_MSG)
                this.hideProgressBar();
              });
            });
          }

          this.showHideModalDialog('add_service');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          serviceForm.reset();
          this.listServiceType(this.currentPage, this.searchInputText);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
    return;
  }

  formatTimeForPrimeNG(date: Date): string {
    return date ? `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}` : '';
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  async editService(serviceForm: NgForm) {
    this.showProgressBar();
    if (serviceForm.value.serviceName === '') {
      this.showErrorMessage('Service Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (serviceForm.value.serviceHours === '00' && serviceForm.value.serviceMints === '00') {
      this.showErrorMessage('Service Duration Field is empty.')
      this.hideProgressBar();
      return;
    } 
    // if (serviceForm.value.location === '') {
    //   this.showErrorMessage('Location Field is empty.')
    //   this.hideProgressBar();
    //   return;
    // } if (serviceForm.value.reminderDay === '') {
    //   this.showErrorMessage('Reminder Day Field is empty.')
    //   this.hideProgressBar();
    //   return;
    // }

    // const serviceData = {
    //   name: this.serviceName,
    //   serviceDuration: this.serviceDuration,
    //   specialInstructions: this.specialInstructions,
    //   location: this.location,
    //   autoSchedule: this.autoSchedule,
    //   reminderDays: this.reminderDay
    // }

    let range = null;

    if(this.serviceType != "One-On-One"){
      // range = this.serviceRangeValue.toString()
      range = this.serviceRangeValue;
    }
    
    if(this.ValidateServiceDetails() == 'null'){
      this.hideProgressBar();
      return;
    }

    this.setCategoryValue();

    let setServiceCategoryDetails = null;
    if(this.serviceCategory != "Recurring Daily"){
      setServiceCategoryDetails = JSON.stringify(this.serviceCategoryDetails)
    }

    let prePaymentDetails = {};
    if(this.prePayment == "true"){
      if(this.prePaymentType == "URL"){
        prePaymentDetails = {
          prePaymentType: this.prePaymentType,
          value: this.prePaymentUrl
        }
      }else{
        prePaymentDetails = {
          prePaymentType: this.prePaymentType,
          value: this.selectedActionButton.id
        }
      } 
    }

    let serviceDate = null;
    if(this.serviceActivation != "custom"){
      serviceDate = null;
    }else{
      serviceDate = new Date(this.serviceStartDate);
    }

    const serviceData = {
      name: this.serviceName,
      serviceDescription: this.serviceDescription,
      specialInstructions: this.specialInstructions ? this.specialInstructions : null,
      location: this.location ? this.location : null,
      serviceCost: this.serviceCost,
      serviceDuration: this.serviceHours+':'+this.serviceMints,
      serviceType: this.serviceType,
      serviceRange: range,
      serviceCategory: this.serviceCategory,
      serviceCategoryDetails: setServiceCategoryDetails,
      concurrency: this.concurrency,
      concurrencySeats: this.concurrency == "true" ? this.concurrencySelectedNumber : null,
      serviceAppointmentReminder: this.serviceAppointmentReminder,
      serviceAppointmentScheduling: this.serviceAppointmentScheduling,
      clientAppointmentRescheduling: this.clientAppointmentRescheduling,
      appointmentWaitinglistAllowed: this.appointmentWaitinglistAllowed,
      walkInsAllowed: this.walkInsAllowed,
      prePayment: this.prePayment,
      serviceUrl: this.serviceUrl ? this.serviceUrl : null,
      serviceVideoUrl: this.serviceVideoUrl ? this.serviceVideoUrl : null,
      serviceClinetConfirmation: this.serviceClinetConfirmation,
      autoSchedule: null,
      reminderDays: this.reminderDays,
      prePaymentUrl: this.prePayment == "true" ? JSON.stringify(prePaymentDetails) : null,
      serviceTime: this.serviceTime,
      customStartTime: moment(this.customStartTime).format('HH:mm'),
      appointmentsPerDay: this.appointmentsPerDay,
      customAppointmentsPerDay: this.customAppointmentPerDay,
      serviceActivation: this.serviceActivation,
      serviceStartDate: this.datePipe.transform(this.serviceStartDate, 'dd-MM-yyyy')!,
      // defaultServiceProvider: this.defaultServiceProvider
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    console.log('Edit Service Form Data --->> ' + JSON.stringify(data));
    this.serviceTypeService.editServiceType(this.serviceId, data).subscribe(
      async (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Service Type Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {

          if(this.checkFilesChanged == true){
            let obs:any[]=[];
            if(this.serviceImage.length > 0){
              this.serviceImage.forEach((obj:any, index:any) => {
                obs.push(this.serviceTypeService.serviceFileUpload(obj, 'image', this.serviceId, index));
              });
            }

            if(this.serviceVideo.length > 0){
              this.serviceVideo.forEach((obj:any, index:any) => {
                obs.push(this.serviceTypeService.serviceFileUpload(obj, 'video', this.serviceId, index));
              });
            }

            this.showProgressBar();
            await forkJoin(obs).subscribe((result:any[])=>{
              result.map((response)=>{
                console.log(response);          
              },(error: any) => {
                this.showErrorMessage(AppSettings.ERROR_MSG);
              });
              this.showHideModalDialog('edit_service');
              this.listServiceType(this.currentPage, this.searchInputText);
            });

            if(this.serviceImage.length > 0 || this.serviceVideo.length > 0 ){
              setTimeout(() =>{
                this.messageService.add({
                  key: 'br',
                  severity: 'info',
                  summary: 'INFO',
                  detail: 'Please wait service images or video are uploading.',
                });
              }, 1000);
            }else{
              if(this.serviceImageFile.length > 0 && this.serviceImage.length ==0){
                this.serviceImageFile.forEach((obj:any) => {
                  this.removeServicetypeFile(obj.id, 'not_show');
                });
              }

              if(this.serviceVideoFile.length > 0 && this.serviceVideo.length ==0){
                this.serviceVideoFile.forEach((obj:any) => {
                  this.removeServicetypeFile(obj.id, 'not_show');
                });
              }

              this.showHideModalDialog('edit_service');
              this.listServiceType(this.currentPage, this.searchInputText);
            }
            
          } else{
            this.showHideModalDialog('edit_service');
            this.listServiceType(this.currentPage, this.searchInputText);
          }                  

          // this.showHideModalDialog('edit_service');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          serviceForm.reset();
          // this.listServiceType();
        } else {
          this.hideProgressBar();
          this.showErrorMessage(json.response.displayMessage)
        }

        // this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listServiceType(pageNumber: any, searchValue: any) {
    this.showProgressBar();
    this.isLoading = true
    let sortData;
    if (this.selectedSortBy != undefined && this.selectedSortBy != null) {
      sortData = {
        sortBy: this.selectedSortBy.split('_')[0],
        sortDirection: this.selectedSortBy.split('_')[1]
      }
    }
    this.serviceTypeService.serviceTypeListWithPaginate(pageNumber, searchValue, sortData).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ServiceType List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeList = json.data;
          this.pageSize = json.additionalData.pageSize;
          this.totalCount = json.additionalData.totalCount;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  removeServicetype(service: any) {
    this.serviceTypeService.removeServiceType(service.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.listServiceType(this.currentPage, this.searchInputText);
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        console.log('Remove User Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  async getServiceType(service: any, type: string) {
    // this.showHideModalDialog(type);
    this.checkFilesChanged = false;
    if (type === 'edit_service' || type === 'show_image_files' || type === 'show_video_files') {
      this.showProgressBar();
      await this.serviceTypeService.getSingleServiceType(service.id).subscribe(
        async (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get User Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.serviceId = json.data.id
            this.serviceName = json.data.name;
            // this.serviceDuration = json.data.serviceDuration;
            this.serviceHours = json.data.serviceDuration.split(':')[0];
            this.serviceMints = json.data.serviceDuration.split(':')[1];
            this.specialInstructions = (json.data.specialInstructions == null) ? '' : json.data.specialInstructions;
            this.location = json.data.location;
            // this.location = CommonFunctions.getBusinessInfo().address;
            this.reminderDays = json.data.reminderDays;
            // this.autoSchedule = json.data.autoSchedule == 'Yes' ? true : false;
            this.serviceAppointmentReminder = json.data.serviceAppointmentReminder;
            this.serviceDescription = json.data.serviceDescription;
            this.serviceCost = json.data.serviceCost;
            this.serviceType = json.data.serviceType;
            this.serviceCategory = json.data.serviceCategory;
            this.serviceClinetConfirmation = json.data.serviceClinetConfirmation;
            this.serviceAppointmentScheduling = json.data.serviceAppointmentScheduling;
            this.clientAppointmentRescheduling = json.data.clientAppointmentRescheduling;
            this.appointmentWaitinglistAllowed = json.data.appointmentWaitinglistAllowed;
            this.prePayment = json.data.prePayment;
            this.concurrency = json.data.concurrency;
            if(json.data.concurrency == "true"){
              this.concurrencySelectedNumber = json.data.concurrencySeats;
              this.groupConcurrencyCountArray = [];
              this.groupConcurrencyCountArray = new Array(Number(json.data.concurrencySeats)).fill(null).map((_, i) => i + 1);
            }
            // this.serviceImage = [];
            this.walkInsAllowed = json.data.walkInsAllowed;
            // if(json.data.serviceUrl == null){
            //   this.serviceUrl = '';
            // }
            this.serviceUrl = json.data.serviceUrl ? json.data.serviceUrl : '';
            this.serviceVideoUrl = json.data.serviceVideoUrl ? json.data.serviceVideoUrl : '';
            if(json.data.serviceRange == null){
              // this.serviceRangeValue = [2,100];
              this.serviceRangeValue = 2;
            }else{
              // this.serviceRangeValue = json.data.serviceRange.split(',');
              let values = json.data.serviceRange.split(',');
              if (values.length > 1) {
                let secondValue = values[1];
                this.serviceRangeValue = parseInt(secondValue);
              }else{
                this.serviceRangeValue = parseInt(json.data.serviceRange);
              }
              
            }
            // this.serviceVideo = [];

            if (json.data.autoSchedule == 'Yes') {
              this.tooltipMsg = 'Customers can schedule appointments themselves';
            } else {
              this.tooltipMsg = 'Customers can not schedule appointments themselves';
            }
            
            this.serviceImageFile = [];
            await this.createBlobFile(json.data.serviceImage);
            await this.createBlobFile(json.data.serviceVideo);
            
            if(json.data.serviceCategory == 'Recurring Weekly' || json.data.serviceCategory  == 'Recurring Bi-Weekly') {
              this.selectedWeek = [];
              this.groupConcurrencyArray = [];
              let jsonObj = JSON.parse(json.data.serviceCategoryDetails);
              jsonObj.forEach((element:any)=>{
                this.selectedWeek.push({"weekName":element.name});
                this.groupConcurrencyArray.push(Number(element.seat));
              });
            }
            
            if(json.data.serviceCategory == 'Recurring Monthly' || json.data.serviceCategory == 'Recurring Custom') {
              this.selectedMonth = [];
              this.groupConcurrencyArray = [];
              let jsonObj = JSON.parse(json.data.serviceCategoryDetails);
              jsonObj.forEach((element:any)=>{
                let utcDate = new Date(element.name);
                let targetOffset = -8 * -60;
                let localTime = utcDate.getTime() + targetOffset * 60000;
                this.selectedMonth.push(new Date(localTime));
                this.groupConcurrencyArray.push(Number(element.seat));
              });             
            }
        
            if(json.data.serviceCategory == 'Non-Recurring') {             
              // this.selectedDate = new Date(JSON.parse(json.data.serviceCategoryDetails));
              const utcDate = new Date(JSON.parse(json.data.serviceCategoryDetails));

              const targetOffset = -8 * -60;
              const localTime = utcDate.getTime() + targetOffset * 60000;
              this.selectedDate = new Date(localTime);
            }
            if(json.data.prePayment == "true"){
              let getPayment = JSON.parse(json.data.prePaymentUrl);
              this.prePaymentType = getPayment.prePaymentType;
              if(getPayment.prePaymentType == "Action Button"){
                this.arrayActionButton.forEach((element:any)=>{
                  if(element.id == getPayment.value){
                    this.selectedActionButton = element
                  }
                });
              }else{
                this.prePaymentUrl = getPayment.value;
              }

            }
            this.serviceTime = json.data.serviceTime;
            if(json.data.customStartTime != null){
             // Received time string from API
              const frontendTimeStr = json.data.customStartTime;

              // Convert string to Date object
              const frontendTime = new Date(`1970-01-01T${frontendTimeStr}Z`);

              // Convert frontendTime to UTC
              const utcTime = new Date(frontendTime.getTime() + frontendTime.getTimezoneOffset() * 60000);

              // Convert UTC time to PST
              const pstTime = new Date(utcTime.setHours(utcTime.getHours())); // Adjust based on PST offset
              // this.formatTime(json.data.customStartTime);
              this.customStartTime = pstTime;
              
            }else{
              this.serviceTime = 'true';
            }
            this.appointmentsPerDay = json.data.appointmentsPerDay;
            if(json.data.customAppointmentsPerDay != null){
              this.customAppointmentPerDay = parseInt(json.data.customAppointmentsPerDay);
            }else{
              this.appointmentsPerDay = 'true';
            }
            if(json.data.serviceActivation != null) {
              this.serviceActivation = json.data.serviceActivation;
              if(json.data.serviceStartDate == "custom"){
                let parts = json.data.serviceStartDate.split('-');
                this.serviceStartDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
              }
            }else{
              this.serviceActivation = "immediate";
            }

            // if(json.data.defaultServiceProvider == null){
            //   this.defaultServiceProvider = false;
            // }else{
            //   this.defaultServiceProvider = json.data.defaultServiceProvider;
            // }
            // this.canDelete = json.data.canDelete;
            
            this.showHideModalDialog(type);
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
  }

  formatTimeString(hours: number | undefined, minutes: number | undefined): string {
    // Default values in case of undefined
    const formattedHours = hours !== undefined ? hours : 0;
    const formattedMinutes = minutes !== undefined ? minutes : 0;
  
    // Format time string
    const hoursString = formattedHours < 10 ? '0' + formattedHours : formattedHours.toString();
    const minutesString = formattedMinutes < 10 ? '0' + formattedMinutes : formattedMinutes.toString();
    
    return `${hoursString}:${minutesString}`;
  }

  formatTime(vale:any){
    this.customStartTime = new Date();
    // console.log(vale.split(':')[0]+" "+ vale.split(':')[1]);
    // this.customStartTime.setUTCHours(vale.split(':')[0], vale.split(':')[1], 0, 0);
    // console.log(this.datePipe.transform(this.customStartTime, 'yyyy-MM-dd HH:mm:ss', 'UTC'););
    // Set the time components to zero
   
    // this.customStartTime.setUTCHours(vale.split(':')[0], vale.split(':')[1], 0, 0);
    // this.customStartTime =
    // Convert the Date object back to a string
    this.customStartTime.setUTCHours(vale.split(':')[0]);
    this.customStartTime.setUTCMinutes(vale.split(':')[1]);
  }
  

  confirmDeleteDialog(service: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this manage services?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeServicetype(service);
      }
    });
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_service':
        this.headerName = 'Edit Manage Services';
        this.displayAddEditModal = !this.displayAddEditModal;
        this.currentPosition = 'service_details';
        break;
      case 'add_service':
        this.clearFormData();
        this.serviceHours = '00';
        this.serviceMints = '30';
        // this.serviceDuration = '00:30'
        this.headerName = 'Manage Services';
        this.displayAddEditModal = !this.displayAddEditModal;        
        this.currentPosition = 'service_details';
        break;
      case 'show_image_files':
        this.headerName = 'Service Images';
        this.displayFilesModal = !this.displayFilesModal;
        break;
      case 'show_video_files':
        this.headerName = 'Service Video';
        this.displayFilesModal = !this.displayFilesModal;
        break;
    }
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  onFilterValueChange(event: any) {

  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.serviceName = '';
    this.serviceDuration = '';
    this.specialInstructions = '';
    // this.location = '';
    this.location = CommonFunctions.getBusinessInfo().address;
    this.reminderDays = 1;
    this.autoSchedule = false;
    this.tooltipMsg = 'Customers can not schedule appointments themselves';

    this.serviceAppointmentReminder = "true";
    this.serviceDescription = '';
    // this.serviceSpecialInstructions = '';
    this.serviceCost = 0;
    this.serviceType = 'One-On-One';
    this.serviceCategory = 'Recurring Daily';
    this.serviceClinetConfirmation= "true";
    this.serviceAppointmentScheduling = 'By Client';
    this.clientAppointmentRescheduling = 'true';
    this.appointmentWaitinglistAllowed = 'false';
    this.prePayment = 'false';
    this.concurrency= 'false';
    this.serviceImage = [];
    this.walkInsAllowed= 'false';
    this.serviceUrl = '';
    this.serviceVideoUrl = '';
    // this.serviceRangeValue = [2,100];
    this.serviceRangeValue = 2;
    this.serviceVideo = [];
    this.serviceCategoryDetails = "";
    this.concurrencyCountArray = new Array(50).fill(null).map((_, i) => i + 1);
    this.concurrencySelectedNumber = 2;
    this.groupConcurrencyArray = [];
    this.groupConcurrencyCountArray = [2];
    this.serviceTime = 'true';
    this.customStartTime = new Date;
    this.appointmentsPerDay = 'true';
    this.customAppointmentPerDay = 1;
  }

  onToggleAutoSchedule(event: any) {
    console.log(JSON.stringify(event))
    if (event.checked) {
      this.tooltipMsg = 'Customers can schedule appointments themselves';
    } else {
      this.tooltipMsg = 'Customers can not schedule appointments themselves';
    }
  }

  paginate(event: any) {
    console.log(event)
    // this.listUsers(event.page + 1, this.searchInputText)
    this.currentPage = event.page + 1;
    this.listServiceType(event.page + 1, this.searchInputText);
  }
  get state(): string {
    return this.utils.state;
  }


  async goToNextAndPrevious(value: string) {
    switch (value) {
      case 'service_details':
        this.messageService.clear('errMsg');
        this.currentPosition = value;
        break;
      case 'appointment_details':
        this.messageService.clear('errMsg');
        if (this.ValidateServiceDetails() == 'null') {
          return;
        }else{
          this.messageService.clear('errMsg');
          this.currentPosition = value;
        }
        break;
      case 'media_details':
        this.messageService.clear('errMsg');
        if(this.validatePrepaymentURL() == 'null'){
          return;
        }else{
          this.messageService.clear('errMsg');
          this.currentPosition = value;
        }
        break;
    }
  }

  ValidateServiceDetails(){
    if (this.serviceName === '') {
      this.showErrorMessage('Service Name Field is empty.')
      return 'null';
    }if (this.serviceName.length >= 50) {
      this.showErrorMessage('Service Name Should not be greater than 50.')
      return 'null';
    } if (this.serviceDescription === '') {
      this.showErrorMessage('Service Description Field is empty.')
      return 'null';
    } if (this.serviceDescription.length >= 200) {
      this.showErrorMessage('Service Description Should not be greater than 200.')
      return 'null';
    } if (this.specialInstructions.length >= 200) {
      this.showErrorMessage('Service Special Should not be greater than 200.')
      return 'null';
    } if (this.serviceCost > 9999.99) {
      this.showErrorMessage('Service Cost Should not be greater than 9999.')
      return 'null';
    } if (this.serviceHours === '00' && this.serviceMints === '00') {
      this.showErrorMessage('Service Duration Field is empty.')
      return 'null';
    } if (this.serviceType === '') {
      this.showErrorMessage('Service Type Field is empty.')
      return 'null';
    } if(this.serviceCategory == 'Recurring Weekly' || this.serviceCategory  == 'Recurring Bi-Weekly') {
      
      if(this.selectedWeek.length == 0){
        this.showErrorMessage(this.serviceCategory +' Field is empty.')
        return 'null';
      }

      if(this.concurrency == "true"){
        if(this.selectedWeek.length != this.groupConcurrencyArray.length){
          this.showErrorMessage('Please select all recurring seats.');
          return 'null'; 
        }
      }
    } if(this.serviceCategory  == 'Recurring Monthly') {
      
      if(this.selectedMonth == undefined){
        this.showErrorMessage(this.serviceCategory +' Field is empty.')
        return 'null';
      }

      if(this.selectedMonth.length < 3) {
        this.showErrorMessage('Recurring monthly minimum you should select three months equal or greater than.')
        return 'null';
      }   
      
      this.validateMonth = [];
      this.selectedMonth.forEach((element:any)=>{
        this.validateMonth.push(element.getMonth()+1);
      });

      const unique = Array.from(new Set(this.validateMonth));
      if(this.validateMonth.length !== unique.length) {
        this.showErrorToastMsg("You can't select multiple date in the same month");
        return 'null';
      }

      if(this.concurrency == "true"){
        if(this.selectedMonth.length != this.groupConcurrencyArray.length){
          this.showErrorMessage('Please select all recurring seats.');
          return 'null'; 
        }
      }
      
    } if(this.serviceCategory == 'Recurring Custom') {
      if(this.selectedMonth == undefined){
        this.showErrorMessage(this.serviceCategory +' Field is empty.')
        return 'null';
      }

      if(this.selectedMonth.length == 0) {
        this.showErrorMessage('Recurring custom minimum you should select one months equal or greater than.')
        return 'null';
      }

      if(this.concurrency == "true"){
        if(this.selectedMonth.length != this.groupConcurrencyArray.length){
          this.showErrorMessage('Please select all recurring seats.');
          return 'null'; 
        }
      }
    }
    if(this.serviceCategory == 'Non-Recurring') {
      if(this.selectedDate == undefined || this.selectedDate == null){
        this.showErrorMessage(this.serviceCategory +' Field is empty.')
        return 'null';
      }
    }
    return 'success';
  }

  serviceTypeFileDeleteConfirmDialog(id: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this service file?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeServicetypeFile(id, 'show_message');
      }
    });
  }

  removeServicetypeFile(id:any, message:any){
    this.serviceTypeService.removeServiceTypeFile(id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          if(message == 'show_message'){
            this.showSuccessMessage(json.response.status, json.response.displayMessage)
          }
          this.serviceImageFile = this.serviceImageFile.filter(item => item.id !== id);
          this.serviceVideoFile = [];
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        console.log('Remove User Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  ShowImagePopUp(file:any){
    this.largeModal.nativeElement.style.display='block';
    this.selectImage = file.fileName;
  }
  
  CloseImagePopUp(){
    this.largeModal.nativeElement.style.display='none';
  }

  serviceFileChangeEvent(event: any, type:any){

    if(type == "image"){
      if(this.headerName != "Edit Manage Services"){
        this.serviceImage = [];
      }
          
      for (let index = 0; index < event.target.files.length; index++) {      
        if(event.target.files[index].size > 10000000){
          this.showErrorMessage(event.target.files[index].name + ' File should not be more than 10 MB!')
        }else{
          event.target.files[index]['objectURL']=this.createbloburl(event.target.files[index],event.target.files[index].type)
          this.serviceImage.push(event.target.files[index]);
          this.checkFilesChanged = true;
        }
      }
    }
 
    if(type == "video"){
      this.serviceVideo = [];    
      if(event.target.files[0].size > 50000000){
        this.showErrorMessage(event.target.files[0].name + ' File should not be more than 10 MB!')
      }else{
        this.serviceVideo.push(event.target.files[0]);
        this.checkFilesChanged = true;
      }
    }
    
  }

  createbloburl(file:any, type:any) {
    var blob = new Blob([file], {
      type: type || 'application/*'
    });  
    file = window.URL.createObjectURL(blob);    
    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }

  removeSelectFile(fileName:any, type:any){
    if(type == 'image'){
      this.serviceImage = this.serviceImage.filter(item => item.name !== fileName);
      this.checkFilesChanged = true;
    }

    if(type == 'video'){
      this.serviceVideo = [];
      this.checkFilesChanged = true;
    }
  }

  randomString(length:any) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  niceBytes(x:any){
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
     let l = 0, n = parseInt(x, 10) || 0;
     while(n >= 1024 && ++l){
         n = n/1024;
     }
     return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

   createBlobFile(fileArray:any) {    
    fileArray.forEach((obj:any) => {
      let blob:any = null;
      var oReq = new XMLHttpRequest();
      oReq.open("GET", this.BaseURL+obj.fileName, true);
      oReq.responseType = "arraybuffer";
      let self:any = this;

      if(obj.fileType == "IMAGE"){
        oReq.onload = function (oEvent) {
          blob = new Blob([oReq.response], {type: "image/jpeg"});
          blob.lastModifiedDate = new Date();
          let setName = self.randomString(10);
          blob.name = setName+'.'+obj.fileName.split("/").pop().split('.')[1];
          blob.objectURL = self.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(blob)));
          self.serviceImage.push(blob);
        };
        oReq.send();
        obj.thumbnailFileName = this.BaseURL+obj.thumbnailFileName;
        obj.fileName = this.BaseURL+obj.fileName;
        this.serviceImageFile.push(obj);
      }

      if(obj.fileType == "VIDEO"){
        oReq.onload = function (oEvent) {
          blob = new Blob([oReq.response], {type: "video/mp4"});
          blob.lastModifiedDate = new Date();
          let setName = self.randomString(10);
          blob.name = setName+'.'+obj.fileName.split("/").pop().split('.')[1];
          self.serviceVideo.push(blob);
        };
        oReq.send();
        obj.fileName = this.BaseURL+obj.fileName;
        this.serviceVideoFile.push(obj);
      }
    });
  }

  goCNN(urlToOpen:any) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(urlToOpen)) {
        url += 'http://';
    }

    url += urlToOpen;
    window.open(url, '_blank');
  }

  validateRecurringMonth(event: Date) {
    
    this.validateMonth = [];
    this.selectedMonth.forEach((element:any)=>{
      this.validateMonth.push(element.getMonth());
    });

    const unique = Array.from(new Set(this.validateMonth));
    if(this.validateMonth.length !== unique.length) {
      let check = false;

      // if (!event || isNaN(new Date(event).getTime())) {
      
      // }else{
        const selectedDate = new Date(event); // Ensure the event is a Date object
        const selectedMonth = selectedDate.getMonth();
        for (let i = this.selectedMonth.length - 1; i >= 0; i--) {
          if (this.selectedMonth[i].getMonth() === selectedMonth) {
            this.selectedMonth.splice(i, 1); 
            check = true;
          }
        }
      // }

      if(check){
       this.selectedMonth.push(event); 
      }
    }
  }

  calendarMonthlyClose() {
    
    if(this.serviceCategory == 'Recurring Monthly' && this.selectedMonth.length < 3) {
      this.showErrorToastMsg('Recurring monthly minimum you should select three months equal or greater than.');
    }else{
      this.datePicker.hideOverlay();
    }
  }

  onChangeServiceCategory(event:any){

    if(event.target.value == 'Recurring Weekly' || event.target.value == 'Recurring Bi-Weekly'){
      this.selectedWeek = [];
    }

    if(event.target.value == 'Recurring Monthly'){
      this.datePicker.value = null;
      this.datePicker.updateInputfield();
      this.selectedMonth = this.datePicker.value;
    }

    if(event.target.value == 'Recurring Custom'){
      this.calendarCustom.value = null;
      this.calendarCustom.updateInputfield();
      this.selectedMonth = this.calendarCustom.value;
    }

    if(event.target.value == 'Non-Recurring'){
      this.calendarNonRecurring.value = null;
      this.calendarNonRecurring.updateInputfield();
      this.selectedDate = this.calendarNonRecurring.value;
    }

    this.groupConcurrencyArray = [];
  }

  concurrencyOnChange(event:any){
    this.groupConcurrencyArray = [];
    this.groupConcurrencyCountArray = [];
    this.groupConcurrencyCountArray = new Array(Number(event.target.value)).fill(null).map((_, i) => i + 1);
    
    if(this.serviceCategory == "Recurring Weekly" || this.serviceCategory == "Recurring Bi-Weekly"){
      this.selectedWeek.forEach(()=>{
        this.groupConcurrencyArray.push(Number(event.target.value));
      });
    }
    
    if(this.serviceCategory == "Recurring Monthly" || this.serviceCategory == "Recurring Custom"){
      this.selectedMonth.forEach(()=>{
        this.groupConcurrencyArray.push(Number(event.target.value));
      });
    }
  }

  setConcurrency(){
    if(this.concurrency){
      this.groupConcurrencyArray = [this.concurrencySelectedNumber];
    }
  }

  validatePrepaymentURL(){

    if(this.prePayment == "true"){
      if(this.prePaymentType == "URL"){
        if(this.prePaymentUrl == ""){
          this.messageService.clear('errMsg');
          this.showErrorMessage('Pre payment URL Field is empty.');
          return 'null';
        }
  
        if (this.prePaymentUrl != '' && !CommonFunctions.validURL(this.prePaymentUrl)) {
          this.showErrorMessage('URL is invalid.')
          this.hideProgressBar();
          return 'null';
        }
      }else if(this.prePaymentType == "Action Button"){
        if(this.selectedActionButton == undefined){
          this.messageService.clear('errMsg');
          this.showErrorMessage('Pre payment Action Button Field is empty.');
          return 'null';
        }
      }
    }

    if(this.serviceAppointmentReminder){
      if(this.reminderDays == undefined || this.reminderDays == null){
        this.messageService.clear('errMsg');
        this.showErrorMessage('Appointment Reminder Days Field is empty.');
        return 'null';
      }
    }
    return 'success';
  }

  getColumnWidth(columnField: string): string {
    // Define widths for specific columns
    const columnWidths: { [key: string]: string } = {
        'serviceDuration': '130px',
        'serviceCategory': '130px',
        // Add more columns as needed
    };

    return columnWidths[columnField] || 'auto'; // Default to 'auto' if no specific width is defined
}

// getRangeValue(): number[] {
//   return Array.from({ length: 98 }, (_, i) => i + 2); // Creates an array [2, 3, ..., 100]
// }
  
}
