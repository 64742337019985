import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) {}

  getServiceUtilizationReport(segment: string, duration: string, startDate?: string, endDate?: string): Observable<any> {  
    const url = `${this.apiUrl}/api/reports/service-utilization`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {
        businessId: CommonFunctions.getBusinessId().toString(),
        segment: segment,
        duration: duration,
        startDate: startDate || '',
        endDate: endDate || '',
      },
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getClientEngagementReport(segment: string, duration: string, startDate?: string, endDate?: string): Observable<any> {
    const url = `${this.apiUrl}/api/reports/client-engagement`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {
        businessId: CommonFunctions.getBusinessId().toString(),
        segment: segment,
        duration: duration,
        startDate: startDate || '',
        endDate: endDate || '',
      },
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
}
