<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>

        <div class="report-container">

          <div style="display: flex; justify-content: flex-end;">
            <button
            class="p-button-raised p-button-sm" pButton pRipple
            label="Export" style="font-size: 14px;margin-right: 4px;" 
            (click)="exportTableToXLSX()"></button>
            <button
            class="p-button-raised p-button-sm" pButton pRipple
            label="Print" style="font-size: 14px;margin-right: 4px;" 
            (click)="printPage('printableArea')"></button>
          </div>
          
            <h2 class="report-title">Service Utilization Report</h2>

            <div class="filter-section">
                <div>
                    <label for="clientSegmentService">Client Segment:</label>
                     <select id="clientSegmentService" [(ngModel)]="selectedClientSegmentService" (change)="onClientSegmentServiceChange()">
                        <option value="All">All</option>
                        <option *ngFor="let segment of clientSegments" [value]="segment.id">{{ segment.name }}</option>
                    </select>
                </div>

                <div class="date-range-section" style="position: relative; display: flex; align-items: center;">
                    <label for="reportDurationService">Report Duration:</label>
                    <select id="reportDurationService" [(ngModel)]="selectedDurationServiceUtilization" (change)="onReportDurationChange($event)">
                      <option value="All">All</option>
                      <option value="YTD" >Year to Date (YTD)</option>
                      <option value="custom">Date Range</option>
                    </select>
                  
                    <div class="date-range" [ngClass]="selectedDurationServiceUtilization !== 'custom' ?'disabledbutton':'' ">
                      <label for="startDateService">Start Date:</label>
                      <p-calendar 
                        class="service-category"
                        id="startDateService" 
                        [(ngModel)]="startDateServiceUtilization" 
                        dateFormat="mm/dd/yy"
                        inputId="startDateService"
                        [showIcon]="true"
                        placeholder="Enter Start Date" 
                        >
                      </p-calendar>
                  
                      <label for="endDateService">End Date:</label>
                      <p-calendar 
                        class="service-category"
                        id="endDateService" 
                        [(ngModel)]="endDateServiceUtilization" 
                        dateFormat="mm/dd/yy"
                        inputId="endDateService"
                        [showIcon]="true"
                        placeholder="Enter End Date"
                        >
                      </p-calendar>
                  
                      <button 
                        (click)="fetchServiceUtilizationData()" 
                        style="margin-left: 20px; cursor: pointer; padding: 5px 10px; background-color: #2196F3;  color: #fff; border: none;">
                        Apply
                      </button>
                    </div>
                  </div>
                  
                  
              </div>
            
              <div *ngIf="isReportLoading" class="alignCenter">
                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                </p-progressSpinner>
              </div>

            <div class="table-scroll-container" id="printableArea">

            <p-table [value]="filteredServiceUtilization" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5, 10, 20, 50, 100]" sortMode="multiple">
              <ng-template pTemplate="header">
                  <tr>
                      <th pSortableColumn="serviceName" class="custom-header">
                          Service Name
                          <p-sortIcon field="serviceName" class="custom-sort-icon" style="color: #fff;"></p-sortIcon>
                      </th>
                      <th pSortableColumn="serviceStatus" class="custom-header">
                          Service Status
                          <p-sortIcon field="serviceStatus" class="custom-sort-icon"></p-sortIcon>
                      </th>
                      <th pSortableColumn="numberOfBookings" class="custom-header">
                          Number of Bookings
                          <p-sortIcon field="numberOfBookings" class="custom-sort-icon"></p-sortIcon>
                      </th>
                      <th pSortableColumn="totalRevenue" class="custom-header">
                          Total Revenue
                          <p-sortIcon field="totalRevenue" class="custom-sort-icon"></p-sortIcon>
                      </th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-service>
                  <tr>
                      <td>{{ service.serviceName }}</td>
                      <td [ngClass]="{ 'active-status': service.serviceStatus === 'Active', 'inactive-status': service.serviceStatus === 'Inactive' }">
                          {{ service.serviceStatus }}
                      </td>
                      <td>{{ service.numberOfBookings }}</td>
                      <td>{{ '$ ' + (service.totalRevenue | number:'1.0-0') }}</td>
                  </tr>
              </ng-template>
          </p-table>
          
            </div>
        </div>          
    </p-scrollPanel>
  </div>
  