<div id="app">
  <app-sidebar *ngIf="showMasterLayout" from="desktop"></app-sidebar>
  <div class="app-content">
    <app-header *ngIf="showMasterLayout"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showMasterLayout"></app-footer>
  </div>
</div>
<!-- NOTIFICATION SIDEBAR -->
<p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="5000" [style]="{width:'20em'}">
  <h5 class="sidebar_title">notifications</h5>
  <span style="vertical-align: middle; font-weight: bold; color: grey;">&nbsp; Only show read</span><p-inputSwitch [(ngModel)]="unReadNotification" style="padding-left: 10px; vertical-align: middle;" (onChange)="notificationHandleChange($event)"></p-inputSwitch> 
  <i class="pi pi-ellipsis-v" (click)="showNotificationOptions()" style=" cursor: pointer;"></i>
  <!-- menu -->
  <div id="myDropdown" class="dropdown-content" *ngIf="showOptions">
    <a (click)="removeAllNotificationConfirmDialog($event, 'unread')">Delete All Unread Notification </a>
    <a (click)="removeAllNotificationConfirmDialog($event, 'read')">Delete All Read Notification </a>
  </div>
  <p-scrollPanel [style]="{width: '100%', height: '95vh','padding-bottom':'40px'}" #sc>
    <ul class="sidebar_ul">
      <li class="list_sidebar" *ngFor="let msg of notifications">
        <a class="sidebar_container" (click)="onNotificationClick(msg)">
          <i *ngIf="msg.readStatus=='unread'" class="fa fa-circle status_online" style="right: 25px;"></i>
          <div [ngClass]="msg.readStatus=='unread'?'sidebar_info_unread':'sidebar_info'">
            <!-- <h4 class="sidebar_info_title">{{msg.title}}</h4><span style="color: #919191">{{msg.description}}</span> -->

            <div class="sidebar_info_header">
              <h4 class="sidebar_info_title">{{msg.title}}</h4>
              <i class="pi pi-trash trash-icon" (click)="removeNotificationConfirmDialog(msg, $event)"></i>
            </div>
            <span style="color: #919191">{{msg.description}}</span>
            <div style="color: #919191; font-weight: 100; font-size: smaller; text-align: end;">{{ getFormattedTimestamp(msg.timestamp) | date: 'MM/dd/yyyy h:mm a' }}</div>
          </div>
        </a>
      </li>
    </ul>
    <div class="alignCenter" style="margin-top: 1rem;" *ngIf="notifications?.length == 0 && !isLoading">
      NO NOTIFICATIONS FOUND
    </div>
    <!-- <p-button type="button" (click)="setIsVisibleSidebar(false)" label="Save" styleClass="p-button-success"></p-button>
  <p-button type="button" (click)="setIsVisibleSidebar(false)" label="Cancel" styleClass="p-button-secondary">
  </p-button> -->
  </p-scrollPanel>
</p-sidebar>

<!-- MENU FOR MOBILE VIEW -->
<p-sidebar [(visible)]="menuSidebar" position="left" [baseZIndex]="5000" [style]="{width:'20em'}"
  [showCloseIcon]="false" (onHide)="closeMenu()">
  <app-sidebar *ngIf="showMasterLayout" from="mobile"></app-sidebar>
</p-sidebar>

<p-dialog header="Account Deactivated" [(visible)]="displayModalDisable" [closable]="false" [modal]="true"
  [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" [baseZIndex]="10000">
  <p class="p-m-0"> Your account is deactivated. Please contact Chatstasy support if you have any query.</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="logout()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<!-- TOAST -->
<p-toast position="bottom-right" key="br" [baseZIndex]="10000"></p-toast>

<!-- REMOVE MODAL -->
<p-confirmDialog key="deleteConfirmDialog" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>



<p-sidebar [(visible)]="visibleViewAppointment" position="right" [baseZIndex]="5000" [style]="{overflow:'auto',width:'36em'}"
    styleClass="different" (onHide)="onSidebarHide()">
    <div *ngIf="!isLoading">
        <div class="p-p-2">
            <h5 class="sidebar_title" *ngIf="appointmentNumber != 'null'"><span>{{appointmentNumber}}</span>
            </h5>
            <ul class="sidebar_ul">
                <li class="list_sidebar">
                    <div class="sidebar_container_notification" style="border-bottom: 1px solid #eee;">
                        <div class="sidebar_info_notification mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-id-badge p-mr-2">
                                </i>
                                <span
                                    *ngIf="singleClientData.firstName != 'null'">{{singleClientData.firstName}}&nbsp;</span>
                                <span *ngIf="singleClientData.middleName != 'null'">{{singleClientData.middleName}}
                                    &nbsp;</span>
                                <span *ngIf="singleClientData.lastName != 'null'">{{singleClientData.lastName}}</span>
                            </span>
                        </div>
                        <div *ngIf="singleClientData.mobileNo != 'null'" class="sidebar_info_notification mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-mobile p-mr-2">
                                </i>{{singleClientData.mobileNo}}</span>
                        </div>
                        <div *ngIf="singleClientData.email != 'null'" class="sidebar_info_notification"><span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-email p-mr-2"></i><a href="javascript:void(0)"
                                    style="text-decoration: underline !important;">{{singleClientData.email}}</a></span>
                        </div>
                    </div>

                    <div *ngIf="appointmentStartDateTime != 'null'" class="sidebar_container_notification">
                        <div class="sidebar_info_notification">
                            <h4 class="sidebar_info_title_notification mb-2">Scheduled On</h4>
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="pi pi-calendar p-mr-2"></i>{{appointmentStartDateTime | date:
                                'MM-dd-yyyy h:mm a'}} - {{appointmentEndDateTime | date:
                                'MM-dd-yyyy h:mm a'}}</span>
                        </div>
                    </div>
                    <div *ngIf="serviceTypeName != 'null'" class="sidebar_container_notification"
                        style="border-top: 1px solid #eee;">
                        <div class="sidebar_info_notification">
                            <h4 class="sidebar_info_title_notification mb-2">Service Type</h4>
                            <span style="color: #919191">{{serviceTypeName}}</span>
                        </div>
                    </div>
                    <div *ngIf="serviceProviderName != 'null'" class="sidebar_container_notification">
                        <div class="sidebar_info_notification">
                            <h4 class="sidebar_info_title_notification mb-2">Provided by</h4>
                            <span style="color: #919191">{{serviceProviderName}}</span>
                        </div>
                    </div>
                    <div *ngIf="specialInstruction != 'null' && specialInstruction != ''" class="sidebar_container_notification">
                        <div class="sidebar_info_notification">
                            <h4 class="sidebar_info_title_notification mb-2">Special Instruction</h4>
                            <span style="color: #919191">{{specialInstruction}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="isLoading" class="p-p-2 p-mr-2">
        <div style="margin: 15px 10px 10px 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'90px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'79px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'104px'"></ngx-shimmer-loading>
        </div>
        <div class="p-grid p-fluid" style="padding: 10px;">
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'49px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'81px'"></ngx-shimmer-loading>
        </div>
    </div>
</p-sidebar>

<!-- TOAST -->
<p-toast position=" bottom-right" key="br"></p-toast>