import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { ImageSnippet } from '../Constant/ImageSnippet';
import { ClientDataService } from '../services/client-data/client-data.service';
import { ClientcommunicationService } from '../services/clientcommunication/clientcommunication.service';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { ProfileService } from '../services/profile/profile.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { element } from 'protractor';
import { map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ClientSegmentationService } from '../services/client-segmentation/client-segmentation.service';

@Component({
  selector: 'app-client-datamanagement',
  templateUrl: './client-datamanagement.component.html',
  styleUrls: ['./client-datamanagement.component.css'],
})
export class ClientDatamanagementComponent implements OnInit {
  @ViewChild('p', { static: false }) paginator?: Paginator;
  @ViewChild('fileInput') fileInput?: ElementRef;
  items: MenuItem[] = [{ label: 'Client Data Management' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  isLoading: boolean = false;
  @Input() id: any;
  noOfData: number = 0;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  mobile: string = '';
  email: string = '';
  country = "US";
  countries: any[] = [];
  userTypeValue: string = 'chatstasy';
  clients: any[] = [];
  searchInputText: string = '';
  successFileName: string = '';
  errorFileName: string = '';
  numErrorRecords: number = 0;
  //Paginator variable
  timer: any;
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;

  // PRIMENG variable
  cols: any[] = [];
  clientType: any[] = [];
  selectedClientType: any;
  sortBy: any[] = [];
  selectedSortBy: any;
  uploadedFiles: any[] = [];
  errorDataList: any[] = [];
  // Dialog display varaible
  headerName: string = '';
  displayImportModal: boolean = false;
  displayAddEditModal: boolean = false;
  disabled: boolean = false;
  importDisabled: boolean = true;
  
  selectedFile: ImageSnippet | undefined;
  public innerWidth: any;
  userModeList: any[] = [];
  userMode = null;
  clientSegments: any[] = [];
  bussinesInfo: any = {};
  roomSubs?: Subscription;
  clientSegmentationList: any[] = [];
  clientSegmentModal: boolean = false;
  clientSegmentArray: any[] = [];

  constructor(
    public utils: UtilsService,
    private profileService: ProfileService,
    private clientDataService: ClientDataService,
    private privilegeService: PrivilegeService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private clientcommunicationService: ClientcommunicationService,
    private clientsegmentationservice: ClientSegmentationService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getClientSegmentationByBusinessId();
    this.listClients(this.currentPage, this.searchInputText);
    this.getCountries();
    this.cols = [
      { field: 'userType', header: 'User Type' },
      { field: 'firstName', header: 'First Name' },
      { field: 'middleName', header: 'Middle Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'mobileNo', header: 'Mobile' },
      { field: 'email', header: 'Email' },
      { field: 'countryName', header: 'Country' },
      { field: 'userMode', header: 'User Mode' },
      // { field: 'clientSegments', header: 'Client Segments' },
    ];
    this.clientType = [
      { name: 'Chatstasy', value: 'chatstasy' },
      { name: ' Non-Chatstasy', value: 'nonchatstasy' },
    ];
    this.userModeList = [
      { name: 'Test', value: 'TEST' },
      { name: 'Live', value: 'LIVE' },
    ];
    this.sortBy = [
      { name: 'First Name Asc', value: 'firstName_ASC' },
      { name: 'First Name Desc', value: 'firstName_DESC' },
      { name: 'Last Name Asc', value: 'lastName_ASC' },
      { name: 'Last Name Desc', value: 'lastName_DESC' },
    ];
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    
    this.roomSubs = this.clientcommunicationService.getAllDocByBusinessId().valueChanges()
    .subscribe((data)=>{
      if (data !== null) {
        console.log(data);
        data.forEach(dataElement=>{
          this.checkIsSubscribe(dataElement);
        });
      }
    });
  }

  checkIsSubscribe(dataElement:any){
    this.clients.forEach(element=>{

      if(element.id == dataElement.recepient_id && dataElement.is_subscribed == true){
        element.isSubscribed = true;
      }

      if(element.id == dataElement.recepient_id && dataElement.is_subscribed == false){
        element.isSubscribed = false;
      }
      // if(element.isSubscribed == false && element.id == dataElement.recepient_id){
      //   element.isSubscribed = true;
      // }else if(element.isSubscribed == true && element.id == dataElement.recepient_id && dataElement.last_message?.toLowerCase() == "stop"){
      //   element.isSubscribed = false;
      // }
    });
  }

  ngOnDestroy() {
    console.log('destroy............................');    
    this.roomSubs?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  isFileValid(fileInput: any) {
    const file = fileInput.target.files[0];
    var size = file.size;
    var name = file.name;
    var extension = name.substring(name.lastIndexOf('.') + 1);
    if (extension == 'csv' || extension == 'xls' || extension == 'xlsx') {
      if (size > 5000000) {
        let finalName = name;
        if (name.length > 20) {
          finalName = name.substring(0, 20) + '...' + extension;
        }
        this.showErrorMessage(finalName + ' File should not be more than 5 MB!')
        return false
      } else {
        return true
      }
    } else {
      this.showErrorMessage('Invalid file, please select only csv, xls, xlsx, xlsm')
      return false
    }
  }

  addFiles(fileInput: any) {
    this.messageService.clear('errMsg');
    if (this.isFileValid(fileInput)) {
      this.uploadedFiles = [];
      const file: File = fileInput.target.files[0];
      this.uploadedFiles.push(file);
    } else {
      this.importDisabled = true;
      this.errorDataList = []
      this.fileInput!.nativeElement.value = "";
    }
  }

  importExcelPreview(fileInput: any) {
    this.showProgressBar()
    if (fileInput == null && fileInput == '') {
      this.showErrorMessage('Please select a file.')
      this.hideProgressBar();
      return;
    }
    this.errorDataList = [];
    this.numErrorRecords = 0;
    const file: File = fileInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.clientDataService.importExcelPreview(this.selectedFile.file).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Import Client Post Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.noOfData = 0;
            json.data.allRecordResult.forEach((element: any) => {
              if (this.noOfData < 25) {
                this.errorDataList.push({
                  row: element.value0.Row,
                  firstName: element.value1.firstName,
                  middleName: element.value1.middleName,
                  lastName: element.value1.lastName,
                  mobileNo: element.value1.mobileNo,
                  email: element.value1.email,
                  userMode: element.value1.userMode,
                  clientSegments: element.value1.clientSegments,
                  country: element.value1.country,
                  userType: element.value1.userType,
                  result: element.value2,
                  resultMessage: element.value3
                })
              }
              this.noOfData++;
            });
            if (json.data.numSuccessRecords > 0) {
              this.showMessage('success', json.data.message)
              this.importDisabled = false;
            }
            if (json.data.numWarningRecords > 0) {
              this.showMessage('warn', json.data.message)
              this.importDisabled = false;
            }
            if (json.data.numErrorRecords > 0) {
              this.showErrorMessage(json.data.message)
              this.numErrorRecords = json.data.numErrorRecords;
              this.importDisabled = true;
            }
            this.successFileName = json.data.inputFile;
            this.errorFileName = json.data.errorFile;
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (err) => {
          console.log(err);
          this.hideProgressBar();
          this.showErrorMessage(AppSettings.ERROR_MSG)
        }
      );
    });
    reader.readAsDataURL(file);
  }


  importClients() {
    this.showProgressBar()
    this.clientDataService.importClients(this.successFileName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Import Client Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('import_client');
          this.showSuccessToastMsg(json.response.displayMessage)
          this.listClients(this.currentPage, this.searchInputText);
        } else {
          this.showErrorMessage(json.response.displayMessage)
          // this.showErrorMessage("Please correct errors to procced")
        }
        this.hideProgressBar();
      },
      (err) => {
        console.log(err);
        this.hideProgressBar();
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  downloadTemplate() {
    this.clientDataService.getDownloadTemplate();
  }

  downloadErrorFile() {
    this.clientDataService.getDownloadErrorFile(this.errorFileName);
  }

  addClient(userForm: NgForm) {
    this.showProgressBar();
    if (userForm.value.firstName === '') {
      this.showErrorMessage('First Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.middleInitial === '') {
      this.showErrorMessage('Middle Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.lastName === '') {
      this.showErrorMessage('Last Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    } if (this.mobile.length <= 9) {
      this.showErrorMessage('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.country === null) {
      this.showErrorMessage('Please select a country.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.userMode === null) {
      this.showErrorMessage('Please select a user mode.')
      this.hideProgressBar();
      return;
    }
    let mob = '+1' + this.mobile;
    
    // let assignCommaStr:any = this.clientSegments;
    // let removeCommaStr = assignCommaStr.replaceAll(",","");
    // let numberVal:any = [];
    // removeCommaStr.split("").forEach((element:any) => {
    //   if (/[0-9]/.test(element) == true || element ==",") {
    //     numberVal.push(element);
    //   }
    // });
    // this.clientSegments = this.clientSegments;

    let clientSegmentsId: any[] = [];
    this.clientSegments.forEach((element: any) => {
      clientSegmentsId.push(element.id)
    });
    
    let client = {
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      mobileNo: mob,
      email: this.email,
      country: this.country,
      userType: 'chatstasy', //this.userTypeValue,
      userMode: this.userMode,
      clientSegments: clientSegmentsId.toString()
    }
    var data = { data: JSON.parse(JSON.stringify(client)) };
    this.clientDataService.addClient(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Client Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_client');
          this.showSuccessToastMsg(json.response.displayMessage)
          this.listClients(this.currentPage, this.searchInputText);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  editClient(userForm: NgForm) {
    this.showProgressBar();
    if (userForm.value.firstName === '') {
      this.showErrorMessage('First Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.middleInitial === '') {
      this.showErrorMessage('Middle Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.lastName === '') {
      this.showErrorMessage('Last Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.mobile.length <= 9) {
      this.showErrorMessage('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.country === null) {
      this.showErrorMessage('Please select a country.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.userMode === null) {
      this.showErrorMessage('Please select a user mode.')
      this.hideProgressBar();
      return;
    }
    let mob = '+1' + this.mobile;

    // let assignCommaStr:any = this.clientSegments;
    // let removeCommaStr = assignCommaStr.replaceAll(",","");
    // let numberVal:any = [];
    // removeCommaStr.split("").forEach((element:any) => {
    //   if (/[0-9]/.test(element) == true || element ==",") {
    //     numberVal.push(element);
    //   }
    // });
    // this.clientSegments = this.clientSegments;

    let clientSegmentsId: any[] = [];
    this.clientSegments.forEach((element: any) => {
      clientSegmentsId.push(element.id)
    });
    
    let client = {
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      mobileNo: mob,
      email: this.email,
      country: this.country,
      userType: 'chatstasy', //this.userTypeValue
      userMode: this.userMode,
      clientSegments: clientSegmentsId.toString() 
    }
    var data = { data: JSON.parse(JSON.stringify(client)) };
    this.clientDataService.editClient(this.id, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Client Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_client');
          this.showSuccessToastMsg(json.response.displayMessage)
          this.listClients(this.currentPage, this.searchInputText);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  confirmDeleteDialog(user: any) {
    console.log('ID --->> ' + user.id);
    this.id = user.id;
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this client ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeUser(user);
      }
    });
  }

  removeUser(user: any) {
    this.clientDataService.removeClient(user.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Remove Client Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessToastMsg(json.response.message)
          this.listClients(this.currentPage, this.searchInputText);
        } else {
          this.showErrorToastMsg(json.response.message);
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar()
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  listClients(pageNumber: any, searchValue: any) {
    console.log('page number:' + pageNumber)
    this.clients = [];
    this.isLoading = true;
    let sortData;
    if (this.selectedSortBy != undefined && this.selectedSortBy != null) {
      sortData = {
        sortBy: this.selectedSortBy.split('_')[0],
        sortDirection: this.selectedSortBy.split('_')[1]
      }
    }
    this.clientDataService.getClientList(pageNumber, searchValue, this.selectedClientType, sortData).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Clients --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clients = json.data;
          this.pageSize = json.additionalData?.pageSize || 0;
          this.totalCount = json.additionalData?.totalCount || 0;
        } else {

        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  getClientSegmentationByBusinessId(){
    this.clientsegmentationservice.getClientSegmentationListByActive().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client Segmentation Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clientSegmentationList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getClient(client: any, type: string) {
    this.clearFormData();
    console.log('ID --->> ' + client.id);
    this.id = client.id;
    if (type === 'edit_client') {
      this.showProgressBar();
      this.clientDataService.getSingleClient(client.id).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get Client Response --->> ' + JSON.stringify(json));
          this.firstName = json.data.firstName;
          this.middleName = json.data.middleName;
          this.lastName = json.data.lastName;
          this.country = json.data.country;
          this.mobile = json.data.mobileNo.replace('+1', '');
          this.email = json.data.email;
          this.userTypeValue = json.data.userType;
          this.userMode = json.data.userMode;
          // if(json.data.clientSegments == null) {
          //   this.clientSegments = "";
          // }else{
          if(json.data.clientSegments != null && json.data.clientSegments != "") {
            this.clientSegments = [];
            this.clientSegmentationList.forEach((element)=>{
              json.data.clientSegments.split(",").forEach((elem:any)=>{
                if(element.id == elem){
                  this.clientSegments.push(element);
                }
              })
            });
          }
          // this.clientSegments = json.data.clientSegments;
          this.showHideModalDialog(type);
          // }
          console.log(this.clientSegments);
          this.hideProgressBar();
        },
        (error) => {
          this.showHideModalDialog(type);
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
  }

  getCountries() {
    this.profileService.getCountries().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        this.countries = json.data;
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
      }
    );
  }


  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showMessage(severity: string, msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: severity,
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      summary: 'Error',
      detail: msg,
    });
  }

  showSuccessToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: 'Success',
      detail: msg,
    });
  }


  clearFormData() {
    this.messageService.clear('errMsg');
    this.firstName = '';
    this.middleName = '';
    this.lastName = '';
    this.mobile = '';
    this.email = '';
    this.country = "US";
    this.userTypeValue = 'chatstasy'
    this.errorDataList = []
    this.uploadedFiles = []
    this.fileInput!.nativeElement.value = null;
    this.successFileName = '';
    this.errorFileName = '';
    this.userMode = null;
    this.clientSegments = [];
  }

  resetUserList() {
    this.searchInputText = '';
    this.currentPage = 1;
    this.paginator?.changePage(0);
    this.listClients(this.currentPage, this.searchInputText);
  }

  onEnterSearchUser(event: any, searchValue: string) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.currentPage = 1;
      this.paginator?.changePage(0);
      this.listClients(this.currentPage, searchValue)
    }, 900);
  }

  onFilterValueChange(event: any) {
    this.listClients(this.currentPage, this.searchInputText);
  }


  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_client':
        this.headerName = 'Edit Client';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_client':
        this.clearFormData();
        this.headerName = 'Add Client';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'import_client':
        this.headerName = 'Upload Clients';
        this.displayImportModal = !this.displayImportModal;
        break;
    }
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  validateDigitOnlyAndComma(event: any){
    let removeCommaStr = event.target.value.replaceAll(",","");
    let numberVal:any = [];
    removeCommaStr.split("").forEach((element:any) => {
      if (/[0-9]/.test(element) == true || element ==",") {
        numberVal.push(element);
      }
    });
    event.target.value = numberVal.toString();
  }

  paginate(event: any) {
    console.log(event)
    this.listClients(event.page + 1, this.searchInputText)
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  ShowClientSegmentModal(clientSegments: any){
    this.headerName = "Segment List";
    this.clientSegmentModal = !this.clientSegmentModal;
    this.clientSegmentArray = clientSegments.slice(1,-1).split(",");
    console.log(this.clientSegmentArray);
  }

  exportExcel(){
    this.clientDataService.ExportExcelClientData();
  }

  get state(): string {
    return this.utils.state;
  }

}
