import { Component, OnInit } from '@angular/core';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import * as XLSX from 'xlsx';

interface Client {
  clientName: string;
  servicesUsed: string[];
  totalRevenue: number;
}

interface Clients {
  id: number;
  name: string;
}

@Component({
  selector: 'app-report-client-engagement-report',
  templateUrl: './client-engagement-report.component.html',
  styleUrls: ['./client-engagement-report.component.css']
})
export class ClientEngagementReportComponent implements OnInit {

  clientSegments: Clients[] = [];

  filteredClientEngagement: Client[] = [];

  sortDirectionClient: { [key: string]: 'asc' | 'desc' | '' } = {
    clientName: 'asc',
    servicesUsed: '',
    totalRevenue: '' 
  };

  selectedDurationClientEngagement = 'YTD';
  startDateClientEngagement: string | undefined;
  endDateClientEngagement: string | undefined;

  
  startDate: string | undefined;
  endDate: string | undefined;

  selectedClientSegmentClient: string = 'All';

  isReportLoading: boolean = false;

  constructor(
    public utils: UtilsService,
    private reportService: ReportService,
    private clientSegmentService: ClientSegmentationService
  ) { }

  ngOnInit(): void {
    this.getClientSegments();
    this.fetchClientEngagementData(); 
    this.sortDirectionClient['clientName'] = 'asc';
  }

  ngAfterViewInit() {
    this.sortClientTable('clientName');
  }

  get state(): string {
    return this.utils.state;
  }

  getClientSegments() {
    this.clientSegmentService.getClientSegmentationList().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.clientSegments = json.data;
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  convertDate(dateString: string | undefined): string | undefined {
    if(dateString != undefined){
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.getFullYear() + '-' + 
                        (dateObject.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                        dateObject.getDate().toString().padStart(2, '0');
      return formattedDate;
    }
    return undefined;
  }

  fetchClientEngagementData() {
    this.isReportLoading = true;
    if(this.selectedDurationClientEngagement === 'custom'){
      this.startDate = this.convertDate(this.startDateClientEngagement);
      this.endDate = this.convertDate(this.endDateClientEngagement);
    }
    
    this.reportService
      .getClientEngagementReport(
        this.selectedClientSegmentClient,
        this.selectedDurationClientEngagement,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response: any) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log(json.data);
          this.filteredClientEngagement = json.data; 
          this.sortDirectionClient['clientName'] = 'desc';
          this.sortClientTable('clientName');
          this.isReportLoading = false;
        },
        (error: any) => {
          console.error(error);
          this.isReportLoading = false;
        }
      );
  }

  sortClientTable(column: keyof Client) {
    const direction = this.sortDirectionClient[column] === 'asc' ? 'desc' : 'asc';
    this.sortDirectionClient[column] = direction;

    Object.keys(this.sortDirectionClient).forEach(key => {
      if (key !== column) {
        this.sortDirectionClient[key] = '';
      }
    });

    this.filteredClientEngagement.sort((a, b) => {
      const valueA = typeof a[column] === 'string' ? (a[column] as string).toLowerCase() : a[column];
      const valueB = typeof b[column] === 'string' ? (b[column] as string).toLowerCase() : b[column];

      if (valueA > valueB) return direction === 'asc' ? 1 : -1;
      if (valueA < valueB) return direction === 'asc' ? -1 : 1;
      return 0;
    });
  }

  onClientSegmentClientChange() {
    this.fetchClientEngagementData();
  }

  onReportDurationChange(event: any): void {
    if (this.selectedDurationClientEngagement === 'YTD') {
      setTimeout(() => {
        this.fetchClientEngagementData();
        this.startDateClientEngagement= undefined;
        this.endDateClientEngagement= undefined;
      }, 0);
    }
  }

  printPage(id: any): void {
    const printContents = document.getElementById(id)?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
        const printContainer = document.createElement('div');
        printContainer.id = 'print-container';
        printContainer.style.display = 'none';
        printContainer.innerHTML = printContents;
        document.body.appendChild(printContainer);

        const children = Array.from(document.body.children);
        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = 'none';
            }
        });

        printContainer.style.display = 'block';
        window.print();

        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = '';
            }
        });

        printContainer.remove();
    }
  }

  // exportTableToCSV() {
  //   const data = this.filteredClientEngagement;
  //   let csvData = this.convertToCSV(data);

  //   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //   const url = window.URL.createObjectURL(blob);

  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = ('0' + (today.getMonth() + 1)).slice(-2);
  //   const day = ('0' + today.getDate()).slice(-2);
  //   const hours = ('0' + today.getHours()).slice(-2);
  //   const minutes = ('0' + today.getMinutes()).slice(-2);
  
  //   const fileName = `${CommonFunctions.getBusinessInfo().name}-Client Engagement Report-${year}${month}${day}-${hours}${minutes}.csv`;
  

  //   const a = document.createElement('a');
  //   a.setAttribute('hidden', '');
  //   a.setAttribute('href', url);
  //   a.setAttribute('download', fileName);
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }
  
  // convertToCSV(data: any[]) {
  //   const headers = 'Client Name,List of Services Used,Revenue';

  //   const rows = data.map(item => {
  //     const row = Object.entries(item).map(([key, value]) => {
  //       if (Array.isArray(value)) {
  //         return `"${value.join(', ')}"`;
  //       }
  //       if (key === 'totalRevenue') {
  //         return `"${value ? `$${value}` : ''}"`;
  //       }
  //       if (typeof value === 'string' && value.includes(',')) {
  //         return `"${value}"`;
  //       }
  //       return value;
  //     });
  //     return row.join(',');
  //   });
  //   return [headers, ...rows].join('\n');
  // }

  exportTableToXLSX() {
    const data = this.filteredClientEngagement;
  
    // Prepare headers and rows
    const headers = ['Client Name', 'List of Services Used', 'Revenue'];
  
    const rows = data.map(item => {
      return [
        item.clientName || '',  // Client Name
        Array.isArray(item.servicesUsed) ? item.servicesUsed.join(', ') : item.servicesUsed || '',  // List of Services Used
        item.totalRevenue ? `$ ${item.totalRevenue}` : ''  // Revenue
      ];
    });
  
    // Construct the XLS content as a string without custom borders
    let xlsContent = `
      <table>
        <thead>
          <tr>
            <th style="text-align: center; border: 1px solid #000; width: 300px;">Client Name</th>
            <th style="text-align: center; border: 1px solid #000; width: 600px;">List of Services Used</th>
            <th style="text-align: center; border: 1px solid #000; width: 150px;">Revenue</th>
          </tr>
        </thead>
        <tbody>
    `;
  
    rows.forEach(row => {
      xlsContent += `
        <tr>
          <td style="text-align: left; vertical-align: middle; border: 1px solid #000">${row[0]}</td>
          <td style="text-align: left; border: 1px solid #000">${row[1]}</td>
          <td style="text-align: right; vertical-align: middle; border: 1px solid #000">${row[2]}</td>
        </tr>
      `;
    });
  
    xlsContent += `
        </tbody>
      </table>
    `;
  
    const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel' });
    const url = URL.createObjectURL(blob);
  
    // Generate the filename
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const fileName = `${CommonFunctions.getBusinessInfo().name}-Client Engagement Report-${year}${month}${day}-${hours}${minutes}.xls`;
  
    // Create a download link and trigger the download
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // exportTableToXLSX() {
  //   const data = this.filteredClientEngagement;

  //   const headers = ['Client Name', 'List of Services Used', 'Revenue'];

  //   const rows = data.map((item: any) => [
  //     item.clientName || '',
  //     Array.isArray(item.servicesUsed) ? item.servicesUsed.join(', ') : item.servicesUsed || '',
  //     '$ '+item.totalRevenue || 0
  //   ]);

  //   const worksheetData = [headers, ...rows];

  //   const ws = XLSX.utils.aoa_to_sheet(worksheetData);

  //   ws['!cols'] = [
  //     { wch: 25 },
  //     { wch: 50 },
  //     { wch: 13 } 
  //   ];

  //   const range = XLSX.utils.decode_range(ws['!ref'] || "");
  //   for (let R = range.s.r; R <= range.e.r; ++R) {
  //     for (let C = range.s.c; C <= range.e.c; ++C) {
  //       const cell_ref = XLSX.utils.encode_cell({ r: R, c: C });
  //       const cell = ws[cell_ref];

  //       if (cell) {
  //         cell.s = cell.s || {};
  //         if (C === 0 || C === 1) {
  //           cell.s.alignment = { horizontal: 'left' };
  //         } else if (C === 2) {
  //           cell.s.alignment = { horizontal: 'right' };
  //         }
  //       }
  //     }
  //   }

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Client Engagement Report');

  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = ('0' + (today.getMonth() + 1)).slice(-2);
  //   const day = ('0' + today.getDate()).slice(-2);
  //   const hours = ('0' + today.getHours()).slice(-2);
  //   const minutes = ('0' + today.getMinutes()).slice(-2);
  //   const fileName = `${CommonFunctions.getBusinessInfo().name}-Client Engagement Report-${year}${month}${day}-${hours}${minutes}.xlsx`;
  //   XLSX.writeFile(wb, fileName);
  // }

}