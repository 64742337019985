import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActionButtonComponent } from './action-button/action-button.component';
import { BusinessCalendarActionComponent } from './business-calendar-action/business-calendar-action.component';
import { BusinessCalendarComponent } from './business-calendar/business-calendar.component';
import { BusinessCalendarComponentModified } from './business-calendar-modified/business-calendar-modified.component';
import { BusinessHoursComponent } from './business-hours/business-hours.component';
import { BusinessInformationComponent } from './business-information/business-information.component';
import { BusinessKeywordsComponent } from './business-keywords/business-keywords.component';
import { ChatButtonConfigurationComponent } from './chat-button-configuration/chat-button-configuration.component';
import { ChatRoomConfigurationComponent } from './chat-room-configuration/chat-room-configuration.component';
import { ClientDatamanagementComponent } from './client-datamanagement/client-datamanagement.component';
import { ChatDefaultPageComponent } from './clientcommunication/chat-area/chat-default-page/chat-default-page.component';
import { ChatRoomComponent } from './clientcommunication/chat-area/chat-room/chat-room.component';
import { ClientcommunicationComponent } from './clientcommunication/clientcommunication.component';
import { ConfigurationWizardComponent } from './configuration-wizard/configuration-wizard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { HowToComponent } from './how-to/how-to.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MessageBotConfigurationComponent } from './message-bot-configuration/message-bot-configuration.component';
import { MessagesTemplateComponent } from './messages-template/messages-template.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaypalComponent } from './paypal/paypal.component';
import { ProfileComponent } from './profile/profile.component';
import { RegistrationComponent } from './registration/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RoleComponent } from './role/role.component';
import { ServiceGalleryComponent } from './service-gallery/service-gallery.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { ServiceTypeComponent } from './service-type/service-type.component';
import { AuthGuard } from './services/authguard/auth.guard';
import { SignupComponent } from './signup/signup.component';
import { SubscriptionAndPaymentsComponent } from './subscription-and-payments/subscription-and-payments.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { SupportTicketsComponent } from './support-tickets/support-tickets.component';
import { UserComponent } from './user/user.component';
import { ClientSegmentationComponent } from './client-segmentation/client-segmentation.component';
import { ChatButtonPromotionComponent } from './chat-button-promotion/chat-button-promotion.component';
import { BusinessHolidaysComponent } from './business-holidays/business-holidays.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { AppointmentCancellationComponent } from './appointment-cancellation/appointment-cancellation.component';
import { ClientEngagementReportComponent } from './client-engagement-report/client-engagement-report.component';
import { ServiceUtilizationReportsComponent } from './service-utilization-report/service-utilization-report.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  // {path: '**', redirectTo: '/page-not-found', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'registration', component: RegistrationComponent,
    children: [
      {
        path: '**',
        component: RegistrationComponent
      }
    ]
  },
  // { path: 'service-gallery', component: ServiceGalleryComponent },
  { path: 'service-gallery', component: ServiceGalleryComponent, children:[{path:':id',component:ServiceGalleryComponent}] },
  { path: 'dts', component: AppointmentConfirmationComponent, children:[{path:':id',component:AppointmentConfirmationComponent}] },
  // { path: 'cnl', component: AppointmentCancellationComponent, children:[{path:':id',component:AppointmentCancellationComponent}] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: SignupComponent },
  { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  {
    path: 'client-communication',
    component: ClientcommunicationComponent,
    children: [
      {
        path: 'room/:id',
        component: ChatRoomComponent
      },
      {
        path: '',
        component: ChatDefaultPageComponent
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'client-datamanagement',
    component: ClientDatamanagementComponent,
    canActivate: [AuthGuard],
  },
  { path: 'role', component: RoleComponent, canActivate: [AuthGuard] },
  { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard] },
  { path: 'paypal', component: PaypalComponent, canActivate: [AuthGuard] },
  { path: 'business-information', component: BusinessInformationComponent, canActivate: [AuthGuard] },
  { path: 'configuration-wizard', component: ConfigurationWizardComponent, canActivate: [AuthGuard] },
  { path: 'business-hours', component: BusinessHoursComponent, canActivate: [AuthGuard] },
  { path: 'message-bot-configuration', component: MessageBotConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'subscription-and-payments', component: SubscriptionAndPaymentsComponent, canActivate: [AuthGuard] },
  { path: 'business-calendar', component: BusinessCalendarComponentModified, canActivate: [AuthGuard] },
  // {path: 'business-calendar-modified', component: BusinessCalendarComponentModified, canActivate: [AuthGuard]},
  // { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  { path: 'client-engagement-report', component: ClientEngagementReportComponent, canActivate: [AuthGuard]},
  { path: 'service-utilization-report', component: ServiceUtilizationReportsComponent, canActivate: [AuthGuard]},
  { path: 'service-type', component: ServiceTypeComponent, canActivate: [AuthGuard] },
  { path: 'service-provider', component: ServiceProviderComponent, canActivate: [AuthGuard] },
  { path: 'chat-button-configuration', component: ChatButtonConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'chat-button-promotion', component: ChatButtonPromotionComponent, canActivate: [AuthGuard] },
  { path: 'chat-room-configuration', component: ChatRoomConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'business-keywords', component: BusinessKeywordsComponent, canActivate: [AuthGuard] },
  { path: 'messages-template', component: MessagesTemplateComponent, canActivate: [AuthGuard] },
  { path: 'frequently-asked-questions', component: FrequentlyAskedQuestionsComponent, canActivate: [AuthGuard] },
  { path: 'support-tickets', component: SupportTicketsComponent, canActivate: [AuthGuard] },
  { path: 'how-to', component: HowToComponent, canActivate: [AuthGuard] },
  {
    path: 'support-chat', component: SupportChatComponent,
    children: [
      {
        path: 'room/:id',
        component: ChatRoomComponent
      },
    ],
    canActivate: [AuthGuard]
  },
  { path: 'business-calendar-action', component: BusinessCalendarActionComponent, children:[{path:':id',component:BusinessCalendarActionComponent}]},
  { path: 'action-button', component: ActionButtonComponent, canActivate: [AuthGuard] },
  { path: 'client-segmentation', component: ClientSegmentationComponent, canActivate: [AuthGuard] },
  { path: 'business-holidays', component: BusinessHolidaysComponent, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
