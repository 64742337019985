import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { AppointmentService } from '../services/appointment/appointment.service';

@Component({
  selector: 'app-appointment-cancellation',
  templateUrl: './appointment-cancellation.component.html',
  styleUrls: ['./appointment-cancellation.component.css']
})
export class AppointmentCancellationComponent implements OnInit {

  disabled: boolean = true;
  isLoading: boolean = false;
  statusInfo: string = "";
  
  constructor(
    private router: Router,
    private appointmentService: AppointmentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    // this.confirmAppointment(this.router.url.split('?')[0].split('/').pop());
  }

  confirmAppointment(id:any){
    this.appointmentService.cancelAppointment(id, true).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.statusInfo = 'success';
          this.showSuccessMessage(json.response.message)
        } else {
          this.statusInfo = 'error';
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );

  }

  cancelAppointmentConfirmDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Please confirm to cancel your appointment?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.confirmAppointment(this.router.url.split('?')[0].split('/').pop());
      }
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

}
